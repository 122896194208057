#hp-product-list {
    margin-bottom: 90px;
    .hp-product-list {
        .block {
            display: block;
            max-width: 1200px;
            padding: 0 15px;
            margin: 0 auto;
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
        }
        &__item {
            width: 100%;
            padding: 0 15px;
            margin-bottom: 30px;
            @media(min-width: 576px) {
                max-width: 50%;
            }
            @media(min-width: 768px) {
                max-width: calc(100% / 3);
            }
            &::marker {
                display: none;
            }
            &:first-child, &:last-child {
                max-width: 100%;
                @media(min-width: 768px) {
                    max-width: calc((100% / 3) * 2);
                }
                .base-product {
                    @media(min-width: 576px) {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        align-content: center;
                    }
                    &__highlighted {
                        @media(min-width: 576px) {
                            display: none;
                        }
                    }
                    &__image {
                        @media(min-width: 576px) {
                            max-width: 50%;
                            margin: 0;
                            padding: 0;
                        }
                        img {
                            @media(min-width: 576px) {
                                position: static;
                                opacity: 1;
                            }
                        }
                    }
                    &__text {
                        @media(min-width: 576px) {
                            max-width: 50%;
                            position: static;
                            padding: 0 15px;
                        }
                        @media(min-width: 1025px) {
                            padding: 0 30px;
                        }
                    }
                    &__description {
                        @media(min-width: 576px) {
                            display: block;
                        }
                    }
                    &__actions {
                        @media(min-width: 576px) {
                            display: none;
                        }
                    }
                }
            }
            .base-product {
                background-color: $color-white;
                clear: both;
                border-radius: 16px;
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
                position: relative;
                overflow: hidden;
                height: 100%;
                &__category {
                    display: inline-flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    background-color: $color-green;
                    color: $color-white;
                    font-size: 11px;
                    line-height: 11px;
                    height: 24px;
                    font-weight: 700;
                    letter-spacing: 0.1em;
                    padding: 0 5px;
                    text-transform: uppercase;
                    border-radius: 3px;
                    margin-bottom: 15px;
                    padding-top: 1px;
                }
                &__highlighted {
                    display: none;
                }
                &__image {
                    width: 100%;
                    margin: 0 0 15px;
                    padding: 0;
                    padding-bottom: 75%;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    img {
                        position: absolute;
                        opacity: 0;
                    }
                }
                &__title {
                    margin-bottom: 5px;
                    &, a {
                        font-size: 18px;
                    }
                }
                &__text {
                    width: 100%;
                    height: auto;
                    padding: 0 15px 45px;
                    position: static;
                    min-height: auto;

                }
                &__actions {
                    display: none;
                }
                &__price {
                    margin: 0;
                    bottom: 0;
                    right: 0;
                    border-radius: 0;
                    border-top-left-radius: 16px;
                }
                &__description {
                    @media(min-width: 576px) {
                        display: none;
                    }
                }
            }
        }
    }
}
