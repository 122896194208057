html {
    body, #index {
        #header {
            .blockcart {
                @media(max-width: 1024px) {
                    display: block;
                    width: 100%;
                    box-shadow: none;
                    position: relative;
                    z-index: 3;
                    order: 3;
                    margin-bottom: 30px;
                }
                .header {
                    @media(max-width: 1024px) {
                        display: none;
                    }
                }
                .dropdown-menu {
                    @media(max-width: 1024px) {
                        position: relative;
                        z-index: 3;
                        display: block;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
