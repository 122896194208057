#burger-btn-wrap {
    float: none;
    position: static;
    padding: 0;
    margin: 0;
}
#burger-menu__btn {
    width: 25px;
    height: 15px;
    cursor: pointer;
    display: block;
    position: absolute;
    top: 22px;
    left: 15px;
    z-index: 20;
    pointer-events: all;
    visibility: visible;
    @media(min-width: 1025px) {
        display: none;
    }
    &:after {
        content: "";
        position: absolute;
        top: -15px;
        right: -15px;
        bottom: -15px;
        left: -15px;
        display: block;
        pointer-events: all;
    }
    span {
        position: absolute;
        height: 3px;
        background-color: $color-font-main;
        display: block;
        width: 100%;
        left: 0;
        margin: auto;
        transition: all .2s ease-in-out;
        transform-origin: 50% 50%;
        pointer-events: all;
        z-index: 99;
        &:first-child {
            top: 0;
            bottom: calc(100% - 3px);
        }
        &:nth-child(2) {
            top: 0;
            bottom: 0;
        }
        &:nth-child(3) {
            top: 0;
            bottom: 0;
        }
        &:last-child {
            bottom: 0;
            top: calc(100% - 3px);
        }
    }
    &.open {
        span {
            &:first-child,
            &:last-child {
                top: 0;
                bottom: 0;
                opacity: 0;
            }
            &:nth-child(2) {
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }
}
