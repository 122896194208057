#main-categories {
    .main-categories {
        &__column {
            float: none;
            width: 100%;
            padding: 0;
            margin: 0;
        }
        &__container {
            max-width: 1200px;
            padding: 0 15px;
            margin: 0 auto 90px;
            .block_content {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;
            }
        }
        &__item {
            padding: 0 15px;
            width: 100%;
            margin-top: -232px;
            backface-visibility: hidden;
            transform: translate3d(0);
            outline: 1px solid transparent;
            transition: transform .4s ease-in-out;
            @media(min-width: 768px) {
                max-width: 50%;
                display: block;
            }
            @media(min-width: 1025px) {
                max-width: 25%;
            }
            @media(min-width: 1366px) {
                margin-top: -30vh;
            }
            @media(min-width: 1920px) {
                margin-top: -25vh;
            }
            &:nth-child(1), &:nth-child(2) {
                @media(max-width: 1024px) {
                    margin-top: -150px;
                }
                @media(max-width: 767px) {
                    margin-top: 30px;
                }
            }
            &:nth-child(3), &:nth-child(4) {
                @media(max-width: 1024px) {
                    margin-top: 30px;
                }
                @media(max-width: 767px) {
                    margin-top: 30px;
                }
            }
            &:nth-child(1) {
                @media(max-width: 767px) {
                    margin-top: -100px;
                }
                @media(max-width: 575px) {
                    margin-top: -50px;
                }
            }
            &:hover {
                @media(min-width: 1025px) {
                    transform: translateY(-20px);
                }
            }
            &-wrap {
                box-shadow: 3px 6px 9px rgba(0,0,0,0.16);
                background-color: $color-white;
                border-radius: 16px;
                overflow: hidden;
                padding-bottom: 25px;
                @media(min-width: 576px) {
                    padding-bottom: 0;
                }
                @media(min-width: 768px) {
                    padding-bottom: 25px;
                }
                & > a {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    @media(min-width: 768px) {
                        display: block;
                    }
                }
            }
            &-icon {
                display: none;
            }
            &-image {
                position: relative;
                background-size: cover;
                background-position: center;
                border-radius: 3px;
                overflow: hidden;
                padding-bottom: 56.25%;
                width: 100%;
                margin-bottom: 20px;
                @media(min-width: 576px) {
                    width: 50%;
                    margin-bottom: 0;
                }
                @media(min-width: 768px) {
                    margin-bottom: 20px;
                    width: 100%;
                }
                @media(min-width: 1025px) {
                    padding-bottom: 76%;
                }
                img {
                    pointer-events: none;
                    position: absolute;
                    visibility: hidden;
                }
                &:after {
                    content: "";
                    background-image: inherit;
                }
            }
            &-text {
                width: 100%;
                @media(min-width: 576px) {
                    width: 50%;
                }
                @media(min-width: 768px) {
                    width: 100%;
                }
            }
            &-title {
                font-weight: 700;
                letter-spacing: 0.05em;
                color: $color-font-main;
                text-align: center;
                padding: 0 15px;
                margin-bottom: 15px;
                font-size: 20px;
                @media(min-width: 1200px) {
                    font-size: 24px;
                }
            }
            &-description {
                color: $color-font-main;
                padding: 0 15px;
                text-align: center;
                font-size: 14px;
                @media(min-width: 1200px) {
                    font-size: 16px;
                }
            }
            &:hover {
                a {
                    color: $color-font-main;
                }
            }
        }
    }
}
