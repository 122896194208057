.categories-grid {
    &__main-wrap {
        max-width: 1200px;
        padding: 0 15px;
        margin: 0 auto;
        .block.categories-grid {
            .title_block {
                @extend .section-title;
                margin-bottom: 30px;
            }
        }
    }
}

.categories-grid.block {
    margin-bottom: 90px;
    .block_content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0 0 99px rgba(0,0,0,0.16);
        .categories-grid {
            &__item {
                width: 100%;
                position: relative;
                overflow: hidden;
                &-wrap {
                    background-position: -999999999px -999999999px;
                    background-repeat: no-repeat;
                    &:after {
                        content: "";
                        display: block;
                        background-color: rgba(0,0,0,0.4);
                        z-index: 3;
                        position: relative;
                        padding-bottom: 75%;
                        transition: background-color .5s ease-in-out;
                        @media(min-width: 768px) {
                            padding-bottom: 100%;
                        }
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        left: -5px;
                        right: -5px;
                        bottom: -5px;
                        top: -5px;
                        transform-origin: 50% 50%;
                        background-image: inherit;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        outline: 1px solid transparent;
                        transform: scale3d(1, 1, 1);
                        transition: transform .5s ease-in-out;
                    }
                    &:hover {
                        @media(min-width: 1025px) {
                            .categories-grid__item-title {
                                color: $color-green;
                            }
                            &:after {
                                background-color: rgba(0,0,0,0.6);
                            }
                            &:before {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
                &-image {
                    width: 100%;
                    height: auto;
                    display: block;
                    position: absolute;
                    opacity: 0;
                }
                &-link {
                }
                &-text {
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    bottom:0;
                    left: 0;
                    right: 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    color: $color-white;
                }
                &-title {
                    display: block;
                    width: 100%;
                    padding: 0 30px;
                    text-align: center;
                    font-size: 26px;
                    line-height: 32px;
                    font-weight: 700;
                    color: $color-white;
                    transition: color .5s ease-in-out;
                }
                &-description {
                    display: block;
                    width: 100%;
                    padding: 0 30px;
                    text-align: center;
                    margin-top: 15px;
                    margin-bottom: 0;
                    font-size: 16px;
                    line-height: 24px;
                    @media(min-width: 1025px) {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
            &__left, &__right {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: flex-start;
                @media(min-width: 768px) {
                    max-width: 50%;
                }
            }
            &__left {
                .categories-grid__item {
                    &:nth-child(2), &:last-child {
                        @media(min-width: 460px) {
                            max-width: 50%;
                        }
                        .categories-grid__item-title {
                            @media(max-width: 1024px) {
                                font-size: 22px;
                                line-height: 26px;
                            }
                        }
                        .categories-grid__item-description {
                            @media(min-width: 460px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            &__right {
                .categories-grid__item {
                    &:first-child, &:nth-child(2) {
                        @media(min-width: 460px) {
                            max-width: 50%;
                        }
                        .categories-grid__item-title {
                            @media(max-width: 1024px) {
                                font-size: 22px;
                                line-height: 26px;
                            }
                        }
                        .categories-grid__item-description {
                            @media(min-width: 460px) {
                                display: none;
                            }
                        }
                    }
                }
            }

        }
    }
}
