#module-smartblog-details {
    #wrapper {
        & > .container {
            & > .row {
                display: flex;
                flex-wrap: wrap;
                #left-column {
                    order: 2;
                    padding: 0 15px;
                    float: none;
                    width: 100%;
                    max-width: 25%;
                    margin: 0;
                    display: none;
                    @media(min-width: 1025px) {
                        display: block;
                    }
                }
                #content-wrapper {
                    order: 1;
                    padding: 0 15px !important;
                    float: none;
                    display: block;
                    width: 100%;
                    margin: 0;
                    @media(min-width: 1025px) {
                        max-width: 75%;
                    }
                }
            }
        }
        #left-column {
            padding: 0 15px !important;
            margin: 0;
            .block-categories {
                display: none;
            }
        }
        #content-wrapper {
            padding: 0 15px !important;
            margin: 0;
            .sdsarticleHeaderContent {
                background-color: $color-white;
                padding: 30px 15px;
                @media(min-width: 576px) {
                    padding: 45px 30px;
                }
            }
            .articleContent {
                box-shadow: 0 0 30px rgba(0,0,0,0.16);
                border-radius: 16px;
            }
            .sdsarticleHeader {
                left: -15px;
                right: -15px;
                bottom: -30px;
                width: calc(100% + 30px);
                background-color: #1a1b1c;
                position: relative;
                @media(min-width: 576px) {
                    width: calc(100% + 60px);
                    left: -30px;
                    right: -30px;
                    bottom: -45px;
                }
                & > span {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    font-size: 0;
                    i {
                        margin-right: 2px;
                        font-size: 14px;
                    }
                    span {
                        margin-right: 15px;
                        font-size: 12px;
                        @media(min-width: 576px) {
                            margin-right: 30px;
                        }
                    }
                }
            }
            #respond {
                background-color: $color-white;
                color: $color-font-main;
                box-shadow: 0 0 30px rgba(0,0,0,0.16);
                padding: 15px;
                @media(min-width: 380px) {
                    padding: 30px;
                }
                @media(min-width: 576px) {
                    padding: 45px;
                }
                #submitComment {
                    background-color: $color-green;
                }
            }
        }
    }
}
