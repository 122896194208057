#category, #product, #module-smartblog-category, #module-smartblog-details, html, body {
    #wrapper {
        .breadcrumb {
            background-image: url("../img/breadcrumb.jpg");
            background-position: -9999999px -9999999px;
            background-repeat: no-repeat;
            padding-bottom: 60px;
            position: relative;
            overflow: hidden;
            background-color: #1a1b1c;
            color: $color-white;
            height: auto;
            padding-top: 120px;
            @media(min-width: 430px) {
                padding-top: 160px;
            }
            &:after {
                content: "";
                position: absolute;
                top: -45px;
                left: -45px;
                right: -45px;
                bottom: -45px;
                display: block;
                background-image: inherit;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                filter: blur(5px) brightness(65%);
            }
            &__list, ol {
                margin-bottom: 5px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
            }
            &__container, .container {
                z-index: 3;
                position: relative;
                padding: 0 15px;
                max-width: 1200px;
                margin: 0 auto;
                justify-content: flex-start;
                flex-direction: row;
                flex-wrap: wrap;
            }
            &__header {
                width: 100%;
                font-size: 32px;
                line-height: 42px;
                margin-bottom: 15px;
                @media(min-width: 576px) {
                    max-width: 75%;
                }
            }
            &__description {
                color: $color-white;
                font-size: 15px;
                line-height: 23px;
                @media(min-width: 576px) {
                    font-size: 18px;
                    line-height: 28px;
                }
                @media(min-width: 768px) {
                    max-width: 75%;
                }
            }
            li {
                display: flex;
                flex-wrap: wrap;
                a {
                    text-align: left;
                    margin: 0 15px 0 0;
                }
                &:last-child {
                    display: block;
                    width: 100%;
                    max-width: none;
                    &, span, a {
                        font-size: 32px;
                        line-height: 42px;
                        text-transform: none;
                        color: $color-white;
                    }
                }
            }
            li.breadcrumb__item {
                display: flex;
                flex-wrap: wrap;
                &-link {
                    text-align: left;
                    margin: 0 15px 0 0;
                }
                &:last-child {
                    display: none;
                }
            }

        }
    }
}
