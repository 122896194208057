.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform; }

.swiper-slide-invisible-blank {
  visibility: hidden; }

/* Auto Height */
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height; }

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px; }
  .swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
    transform-style: preserve-3d; }
  .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal, .swiper-container-wp8-horizontal > .swiper-wrapper {
  touch-action: pan-y; }

.swiper-container-wp8-vertical, .swiper-container-wp8-vertical > .swiper-wrapper {
  touch-action: pan-x; }

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit; }

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0; }

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */ }

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table; }

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none; }

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0; }

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all; }

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px; }

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear; }

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px; }

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px; }

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0; }

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll; }

* {
  box-sizing: border-box; }

.disable-column {
  display: block;
  float: none !important;
  width: 100% !important;
  max-width: 100% !important;
  clear: both !important;
  padding: 0 !important; }

#wrapper > .row {
  margin: 0 !important; }

#wrapper > .container {
  margin-top: 45px !important; }
  @media (min-width: 1025px) {
    #wrapper > .container {
      margin-top: 100px !important; } }

.container {
  max-width: 1200px !important;
  padding: 0 15px !important;
  width: 100% !important;
  margin: 0 auto !important; }

.section-title, .categories-grid__main-wrap .block.categories-grid .title_block {
  text-align: center;
  font-size: 36px;
  color: #349013; }

#burger-btn-wrap {
  float: none;
  position: static;
  padding: 0;
  margin: 0; }

#burger-menu__btn {
  width: 25px;
  height: 15px;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 22px;
  left: 15px;
  z-index: 20;
  pointer-events: all;
  visibility: visible; }
  @media (min-width: 1025px) {
    #burger-menu__btn {
      display: none; } }
  #burger-menu__btn:after {
    content: "";
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    display: block;
    pointer-events: all; }
  #burger-menu__btn span {
    position: absolute;
    height: 3px;
    background-color: #1a1b1c;
    display: block;
    width: 100%;
    left: 0;
    margin: auto;
    transition: all .2s ease-in-out;
    transform-origin: 50% 50%;
    pointer-events: all;
    z-index: 99; }
    #burger-menu__btn span:first-child {
      top: 0;
      bottom: calc(100% - 3px); }
    #burger-menu__btn span:nth-child(2) {
      top: 0;
      bottom: 0; }
    #burger-menu__btn span:nth-child(3) {
      top: 0;
      bottom: 0; }
    #burger-menu__btn span:last-child {
      bottom: 0;
      top: calc(100% - 3px); }
  #burger-menu__btn.open span:first-child, #burger-menu__btn.open span:last-child {
    top: 0;
    bottom: 0;
    opacity: 0; }
  #burger-menu__btn.open span:nth-child(2) {
    transform: rotate(45deg); }
  #burger-menu__btn.open span:nth-child(3) {
    transform: rotate(-45deg); }

.base-product {
  background-color: #fff;
  clear: both;
  border-radius: 16px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden; }
  .base-product__category {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #349013;
    color: #fff;
    font-size: 11px;
    line-height: 11px;
    height: 24px;
    font-weight: 700;
    letter-spacing: 0.1em;
    padding: 0 5px;
    text-transform: uppercase;
    border-radius: 3px;
    margin-bottom: 5px;
    padding-top: 1px; }
  .base-product__add {
    margin: 0;
    left: auto;
    right: 0;
    top: 0;
    bottom: auto;
    border-radius: 0; }
    .base-product__add .add-to-cart {
      border-radius: 0;
      border-bottom-left-radius: 16px;
      margin-left: 0;
      background-color: #349013; }
  .base-product__highlighted {
    display: none; }
  .base-product__reviews {
    margin: 0 0 20px; }
  .base-product__image {
    width: 100%;
    margin: 0 0 15px;
    padding: 0;
    padding-bottom: 75%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    .base-product__image img {
      position: absolute;
      opacity: 0; }
  .base-product__title {
    margin-bottom: 5px;
    color: #1a1b1c;
    overflow: visible !important; }
    .base-product__title, .base-product__title a {
      font-size: 18px; }
  .base-product__text {
    width: 100%;
    height: auto;
    padding: 0 15px 45px; }
  .base-product__actions {
    display: none; }
  .base-product__price {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    background-color: #349013;
    color: #fff;
    border-radius: 0;
    border-top-left-radius: 16px;
    font-size: 16px;
    font-weight: 600;
    padding: 7px 10px; }
  .base-product__description {
    font-size: 16px;
    line-height: 24px; }

#wrapper .base-product__add {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto; }
  #wrapper .base-product__add .add-to-cart {
    border-radius: 0;
    border-bottom-left-radius: 16px; }

#wrapper .base-product .thumbnail-container {
  margin-bottom: 0; }

#wrapper #left-column .product-list__column {
  margin-bottom: 0; }

#category #wrapper #left-column .base-product, #module-smartblog-category #wrapper #left-column .base-product, #module-smartblog-details #wrapper #left-column .base-product {
  margin-bottom: 30px; }

#category #wrapper #left-column .product-list__column, #module-smartblog-category #wrapper #left-column .product-list__column, #module-smartblog-details #wrapper #left-column .product-list__column {
  margin-bottom: 0; }

@media (max-width: 1024px) {
  html body #header .blockcart, html #index #header .blockcart {
    display: block;
    width: 100%;
    box-shadow: none;
    position: relative;
    z-index: 3;
    order: 3;
    margin-bottom: 30px; } }

@media (max-width: 1024px) {
  html body #header .blockcart .header, html #index #header .blockcart .header {
    display: none; } }

@media (max-width: 1024px) {
  html body #header .blockcart .dropdown-menu, html #index #header .blockcart .dropdown-menu {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: none; } }

#home-section {
  position: relative;
  max-height: 850px;
  overflow: hidden; }
  #home-section:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.35); }
  #home-section .home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    min-height: 550px; }
    @media (min-width: 576px) {
      #home-section .home {
        min-height: 700px; } }
    @media (min-width: 1025px) {
      #home-section .home {
        background-position: center; } }
    #home-section .home__description {
      position: absolute;
      width: 100%;
      max-width: 1200px;
      padding: 0 15px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      z-index: 6;
      top: 250px;
      left: 0;
      right: 0;
      pointer-events: none; }
    #home-section .home__header {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      font-weight: 700;
      color: #fff;
      pointer-events: none;
      text-align: center;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 10px; }
      @media (min-width: 400px) {
        #home-section .home__header {
          font-size: 40px;
          line-height: 46px; } }
      @media (min-width: 576px) {
        #home-section .home__header {
          font-size: 54px;
          line-height: 62px;
          text-align: left; } }
      @media (min-width: 768px) {
        #home-section .home__header {
          font-size: 64px;
          line-height: 72px; } }
    #home-section .home__text {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      color: #fff;
      pointer-events: none;
      text-align: center;
      font-size: 16px;
      line-height: 20px; }
      @media (min-width: 400px) {
        #home-section .home__text {
          font-size: 18px;
          line-height: 24px; } }
      @media (min-width: 576px) {
        #home-section .home__text {
          font-size: 24px;
          line-height: 30px;
          text-align: left; } }
      @media (min-width: 768px) {
        #home-section .home__text {
          font-size: 30px;
          line-height: 38px; } }
    #home-section .home__image img {
      opacity: 0; }

#main-categories .main-categories__column {
  float: none;
  width: 100%;
  padding: 0;
  margin: 0; }

#main-categories .main-categories__container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto 90px; }
  #main-categories .main-categories__container .block_content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }

#main-categories .main-categories__item {
  padding: 0 15px;
  width: 100%;
  margin-top: -232px;
  backface-visibility: hidden;
  transform: translate3d(0);
  outline: 1px solid transparent;
  transition: transform .4s ease-in-out; }
  @media (min-width: 768px) {
    #main-categories .main-categories__item {
      max-width: 50%;
      display: block; } }
  @media (min-width: 1025px) {
    #main-categories .main-categories__item {
      max-width: 25%; } }
  @media (min-width: 1366px) {
    #main-categories .main-categories__item {
      margin-top: -30vh; } }
  @media (min-width: 1920px) {
    #main-categories .main-categories__item {
      margin-top: -25vh; } }
  @media (max-width: 1024px) {
    #main-categories .main-categories__item:nth-child(1), #main-categories .main-categories__item:nth-child(2) {
      margin-top: -150px; } }
  @media (max-width: 767px) {
    #main-categories .main-categories__item:nth-child(1), #main-categories .main-categories__item:nth-child(2) {
      margin-top: 30px; } }
  @media (max-width: 1024px) {
    #main-categories .main-categories__item:nth-child(3), #main-categories .main-categories__item:nth-child(4) {
      margin-top: 30px; } }
  @media (max-width: 767px) {
    #main-categories .main-categories__item:nth-child(3), #main-categories .main-categories__item:nth-child(4) {
      margin-top: 30px; } }
  @media (max-width: 767px) {
    #main-categories .main-categories__item:nth-child(1) {
      margin-top: -100px; } }
  @media (max-width: 575px) {
    #main-categories .main-categories__item:nth-child(1) {
      margin-top: -50px; } }
  @media (min-width: 1025px) {
    #main-categories .main-categories__item:hover {
      transform: translateY(-20px); } }
  #main-categories .main-categories__item-wrap {
    box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    padding-bottom: 25px; }
    @media (min-width: 576px) {
      #main-categories .main-categories__item-wrap {
        padding-bottom: 0; } }
    @media (min-width: 768px) {
      #main-categories .main-categories__item-wrap {
        padding-bottom: 25px; } }
    #main-categories .main-categories__item-wrap > a {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center; }
      @media (min-width: 768px) {
        #main-categories .main-categories__item-wrap > a {
          display: block; } }
  #main-categories .main-categories__item-icon {
    display: none; }
  #main-categories .main-categories__item-image {
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    overflow: hidden;
    padding-bottom: 56.25%;
    width: 100%;
    margin-bottom: 20px; }
    @media (min-width: 576px) {
      #main-categories .main-categories__item-image {
        width: 50%;
        margin-bottom: 0; } }
    @media (min-width: 768px) {
      #main-categories .main-categories__item-image {
        margin-bottom: 20px;
        width: 100%; } }
    @media (min-width: 1025px) {
      #main-categories .main-categories__item-image {
        padding-bottom: 76%; } }
    #main-categories .main-categories__item-image img {
      pointer-events: none;
      position: absolute;
      visibility: hidden; }
    #main-categories .main-categories__item-image:after {
      content: "";
      background-image: inherit; }
  #main-categories .main-categories__item-text {
    width: 100%; }
    @media (min-width: 576px) {
      #main-categories .main-categories__item-text {
        width: 50%; } }
    @media (min-width: 768px) {
      #main-categories .main-categories__item-text {
        width: 100%; } }
  #main-categories .main-categories__item-title {
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #1a1b1c;
    text-align: center;
    padding: 0 15px;
    margin-bottom: 15px;
    font-size: 20px; }
    @media (min-width: 1200px) {
      #main-categories .main-categories__item-title {
        font-size: 24px; } }
  #main-categories .main-categories__item-description {
    color: #1a1b1c;
    padding: 0 15px;
    text-align: center;
    font-size: 14px; }
    @media (min-width: 1200px) {
      #main-categories .main-categories__item-description {
        font-size: 16px; } }
  #main-categories .main-categories__item:hover a {
    color: #1a1b1c; }

#main-top .swiper-products.swiper-container {
  margin-bottom: 60px; }
  #main-top .swiper-products.swiper-container .swiper-products__title {
    text-align: center;
    color: #349013;
    padding: 0;
    margin: 0 0 15px;
    font-size: 24px; }
    @media (min-width: 576px) {
      #main-top .swiper-products.swiper-container .swiper-products__title {
        font-size: 28px; } }
    @media (min-width: 768px) {
      #main-top .swiper-products.swiper-container .swiper-products__title {
        font-size: 36px; } }
  #main-top .swiper-products.swiper-container .swiper-wrapper {
    padding-top: 50px;
    padding-bottom: 50px; }
  #main-top .swiper-products.swiper-container .swiper-slide {
    box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    border-radius: 16px;
    overflow: hidden; }
    #main-top .swiper-products.swiper-container .swiper-slide .base-product {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center; }
      #main-top .swiper-products.swiper-container .swiper-slide .base-product__image {
        width: 100%;
        background-position: center;
        background-size: cover;
        margin: 0;
        padding-bottom: 0; }
        @media (min-width: 576px) {
          #main-top .swiper-products.swiper-container .swiper-slide .base-product__image {
            max-width: 50%; } }
        #main-top .swiper-products.swiper-container .swiper-slide .base-product__image:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background: #ffffff;
          opacity: 0.2; }
        #main-top .swiper-products.swiper-container .swiper-slide .base-product__image a {
          position: relative;
          z-index: 3; }
        #main-top .swiper-products.swiper-container .swiper-slide .base-product__image img {
          opacity: 0;
          position: relative; }
      #main-top .swiper-products.swiper-container .swiper-slide .base-product__text {
        width: 100%;
        height: 100%;
        background-color: inherit;
        position: static;
        padding: 10px 15px 45px; }
        @media (min-width: 576px) {
          #main-top .swiper-products.swiper-container .swiper-slide .base-product__text {
            max-width: 50%;
            padding: 0 30px 0 15px; } }
      @media (max-width: 767px) {
        #main-top .swiper-products.swiper-container .swiper-slide .base-product__description {
          font-size: 14px;
          line-height: 22px; } }
      #main-top .swiper-products.swiper-container .swiper-slide .base-product__highlighted {
        display: none; }
      #main-top .swiper-products.swiper-container .swiper-slide .base-product__title, #main-top .swiper-products.swiper-container .swiper-slide .base-product__title a {
        margin: 0 0 3px;
        font-size: 20px;
        line-height: 26px; }
        @media (min-width: 758px) {
          #main-top .swiper-products.swiper-container .swiper-slide .base-product__title, #main-top .swiper-products.swiper-container .swiper-slide .base-product__title a {
            font-size: 24px;
            line-height: 30px; } }
      #main-top .swiper-products.swiper-container .swiper-slide .base-product__category {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: #349013;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        height: 24px;
        font-weight: 700;
        letter-spacing: 0.1em;
        padding: 0 5px;
        text-transform: uppercase;
        border-radius: 3px;
        margin-bottom: 15px;
        padding-top: 1px; }
      #main-top .swiper-products.swiper-container .swiper-slide .base-product__price {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
        background-color: #349013;
        border-radius: 0;
        border-top-left-radius: 16px; }
      #main-top .swiper-products.swiper-container .swiper-slide .base-product__actions {
        display: none; }

.categories-grid__main-wrap {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto; }
  .categories-grid__main-wrap .block.categories-grid .title_block {
    margin-bottom: 30px; }

.categories-grid.block {
  margin-bottom: 90px; }
  .categories-grid.block .block_content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16); }
    .categories-grid.block .block_content .categories-grid__item {
      width: 100%;
      position: relative;
      overflow: hidden; }
      .categories-grid.block .block_content .categories-grid__item-wrap {
        background-position: -999999999px -999999999px;
        background-repeat: no-repeat; }
        .categories-grid.block .block_content .categories-grid__item-wrap:after {
          content: "";
          display: block;
          background-color: rgba(0, 0, 0, 0.4);
          z-index: 3;
          position: relative;
          padding-bottom: 75%;
          transition: background-color .5s ease-in-out; }
          @media (min-width: 768px) {
            .categories-grid.block .block_content .categories-grid__item-wrap:after {
              padding-bottom: 100%; } }
        .categories-grid.block .block_content .categories-grid__item-wrap:before {
          content: "";
          position: absolute;
          left: -5px;
          right: -5px;
          bottom: -5px;
          top: -5px;
          transform-origin: 50% 50%;
          background-image: inherit;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          outline: 1px solid transparent;
          transform: scale3d(1, 1, 1);
          transition: transform .5s ease-in-out; }
        @media (min-width: 1025px) {
          .categories-grid.block .block_content .categories-grid__item-wrap:hover .categories-grid__item-title {
            color: #349013; }
          .categories-grid.block .block_content .categories-grid__item-wrap:hover:after {
            background-color: rgba(0, 0, 0, 0.6); }
          .categories-grid.block .block_content .categories-grid__item-wrap:hover:before {
            transform: scale(1.1); } }
      .categories-grid.block .block_content .categories-grid__item-image {
        width: 100%;
        height: auto;
        display: block;
        position: absolute;
        opacity: 0; }
      .categories-grid.block .block_content .categories-grid__item-text {
        position: absolute;
        z-index: 5;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: #fff; }
      .categories-grid.block .block_content .categories-grid__item-title {
        display: block;
        width: 100%;
        padding: 0 30px;
        text-align: center;
        font-size: 26px;
        line-height: 32px;
        font-weight: 700;
        color: #fff;
        transition: color .5s ease-in-out; }
      .categories-grid.block .block_content .categories-grid__item-description {
        display: block;
        width: 100%;
        padding: 0 30px;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px; }
        @media (min-width: 1025px) {
          .categories-grid.block .block_content .categories-grid__item-description {
            font-size: 18px;
            line-height: 28px; } }
    .categories-grid.block .block_content .categories-grid__left, .categories-grid.block .block_content .categories-grid__right {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start; }
      @media (min-width: 768px) {
        .categories-grid.block .block_content .categories-grid__left, .categories-grid.block .block_content .categories-grid__right {
          max-width: 50%; } }
    @media (min-width: 460px) {
      .categories-grid.block .block_content .categories-grid__left .categories-grid__item:nth-child(2), .categories-grid.block .block_content .categories-grid__left .categories-grid__item:last-child {
        max-width: 50%; } }
    @media (max-width: 1024px) {
      .categories-grid.block .block_content .categories-grid__left .categories-grid__item:nth-child(2) .categories-grid__item-title, .categories-grid.block .block_content .categories-grid__left .categories-grid__item:last-child .categories-grid__item-title {
        font-size: 22px;
        line-height: 26px; } }
    @media (min-width: 460px) {
      .categories-grid.block .block_content .categories-grid__left .categories-grid__item:nth-child(2) .categories-grid__item-description, .categories-grid.block .block_content .categories-grid__left .categories-grid__item:last-child .categories-grid__item-description {
        display: none; } }
    @media (min-width: 460px) {
      .categories-grid.block .block_content .categories-grid__right .categories-grid__item:first-child, .categories-grid.block .block_content .categories-grid__right .categories-grid__item:nth-child(2) {
        max-width: 50%; } }
    @media (max-width: 1024px) {
      .categories-grid.block .block_content .categories-grid__right .categories-grid__item:first-child .categories-grid__item-title, .categories-grid.block .block_content .categories-grid__right .categories-grid__item:nth-child(2) .categories-grid__item-title {
        font-size: 22px;
        line-height: 26px; } }
    @media (min-width: 460px) {
      .categories-grid.block .block_content .categories-grid__right .categories-grid__item:first-child .categories-grid__item-description, .categories-grid.block .block_content .categories-grid__right .categories-grid__item:nth-child(2) .categories-grid__item-description {
        display: none; } }

#hp-category, #hp-category-left, #hp-category-right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: relative;
  margin-bottom: 90px;
  overflow: hidden; }
  #hp-category .hp-category, #hp-category-left .hp-category, #hp-category-right .hp-category {
    margin: 0;
    padding: 0; }
    #hp-category .hp-category__left, #hp-category-left .hp-category__left, #hp-category-right .hp-category__left {
      width: 100%; }
      @media (min-width: 1280px) {
        #hp-category .hp-category__left, #hp-category-left .hp-category__left, #hp-category-right .hp-category__left {
          max-width: 35% !important; } }
    #hp-category .hp-category__right, #hp-category-left .hp-category__right, #hp-category-right .hp-category__right {
      width: 100%; }
      @media (min-width: 1280px) {
        #hp-category .hp-category__right, #hp-category-left .hp-category__right, #hp-category-right .hp-category__right {
          max-width: 65% !important; } }
    #hp-category .hp-category__item-wrap, #hp-category-left .hp-category__item-wrap, #hp-category-right .hp-category__item-wrap {
      position: relative;
      margin: 0; }
    #hp-category .hp-category__item-btn, #hp-category-left .hp-category__item-btn, #hp-category-right .hp-category__item-btn {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      width: 100%;
      max-width: 200px;
      min-height: 45px;
      background-color: #349013;
      color: #fff;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      transition: color .2s ease-in-out, box-shadow .2s ease-in-out; }
      #hp-category .hp-category__item-btn:after, #hp-category-left .hp-category__item-btn:after, #hp-category-right .hp-category__item-btn:after {
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        background-color: #fff;
        right: 100%;
        transition: right .2s ease-in-out; }
      #hp-category .hp-category__item-btn-span, #hp-category-left .hp-category__item-btn-span, #hp-category-right .hp-category__item-btn-span {
        position: relative;
        z-index: 3;
        transition: color .2s ease-in-out; }
      @media (min-width: 1025px) {
        #hp-category .hp-category__item-btn:hover, #hp-category-left .hp-category__item-btn:hover, #hp-category-right .hp-category__item-btn:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }
          #hp-category .hp-category__item-btn:hover .hp-category__item-btn-span, #hp-category-left .hp-category__item-btn:hover .hp-category__item-btn-span, #hp-category-right .hp-category__item-btn:hover .hp-category__item-btn-span {
            color: #349013; }
          #hp-category .hp-category__item-btn:hover:after, #hp-category-left .hp-category__item-btn:hover:after, #hp-category-right .hp-category__item-btn:hover:after {
            right: 0; } }
    #hp-category .hp-category__item-image, #hp-category-left .hp-category__item-image, #hp-category-right .hp-category__item-image {
      float: none;
      padding: 0;
      background-repeat: no-repeat;
      background-position: -999999999px -999999999px;
      overflow: hidden;
      position: relative;
      min-height: 250px; }
      @media (min-width: 576px) {
        #hp-category .hp-category__item-image, #hp-category-left .hp-category__item-image, #hp-category-right .hp-category__item-image {
          min-height: 300px; } }
      @media (min-width: 768px) {
        #hp-category .hp-category__item-image, #hp-category-left .hp-category__item-image, #hp-category-right .hp-category__item-image {
          overflow: visible;
          padding-bottom: 0;
          position: static;
          min-height: auto; } }
      #hp-category .hp-category__item-image:after, #hp-category-left .hp-category__item-image:after, #hp-category-right .hp-category__item-image:after {
        content: "";
        background-position: right;
        background-image: inherit;
        background-repeat: no-repeat;
        display: block;
        padding-bottom: 0%;
        position: absolute;
        top: -30px;
        bottom: -30px;
        left: -30px;
        right: -30px;
        background-size: cover;
        filter: blur(13px); }
        @media (min-width: 768px) {
          #hp-category .hp-category__item-image:after, #hp-category-left .hp-category__item-image:after, #hp-category-right .hp-category__item-image:after {
            position: static;
            padding-bottom: 40%;
            background-size: 50%;
            filter: none; } }
        @media (min-width: 1025px) {
          #hp-category .hp-category__item-image:after, #hp-category-left .hp-category__item-image:after, #hp-category-right .hp-category__item-image:after {
            padding-bottom: 30%; } }
        @media (min-width: 1280px) {
          #hp-category .hp-category__item-image:after, #hp-category-left .hp-category__item-image:after, #hp-category-right .hp-category__item-image:after {
            padding-bottom: 100%;
            background-size: cover;
            background-position: center;
            border-radius: 0; } }
      #hp-category .hp-category__item-image:before, #hp-category-left .hp-category__item-image:before, #hp-category-right .hp-category__item-image:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.5); }
        @media (min-width: 768px) {
          #hp-category .hp-category__item-image:before, #hp-category-left .hp-category__item-image:before, #hp-category-right .hp-category__item-image:before {
            background-color: transparent; } }
        @media (min-width: 1280px) {
          #hp-category .hp-category__item-image:before, #hp-category-left .hp-category__item-image:before, #hp-category-right .hp-category__item-image:before {
            background-color: rgba(0, 0, 0, 0.5); } }
      #hp-category .hp-category__item-image img, #hp-category-left .hp-category__item-image img, #hp-category-right .hp-category__item-image img {
        max-width: 100%;
        width: 100%;
        display: block;
        height: auto;
        position: absolute;
        opacity: 0; }
    #hp-category .hp-category__item-link, #hp-category-left .hp-category__item-link, #hp-category-right .hp-category__item-link {
      width: 100%;
      display: block; }
      @media (min-width: 1280px) {
        #hp-category .hp-category__item-link, #hp-category-left .hp-category__item-link, #hp-category-right .hp-category__item-link {
          color: #fff; } }
    #hp-category .hp-category__item-text, #hp-category-left .hp-category__item-text, #hp-category-right .hp-category__item-text {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      padding: 0 30px;
      justify-content: center;
      text-align: center;
      z-index: 5; }
      @media (min-width: 768px) {
        #hp-category .hp-category__item-text, #hp-category-left .hp-category__item-text, #hp-category-right .hp-category__item-text {
          text-align: left;
          justify-content: flex-start;
          padding: 0 calc(50% + 15px) 0 15px; } }
      @media (min-width: 1280px) {
        #hp-category .hp-category__item-text, #hp-category-left .hp-category__item-text, #hp-category-right .hp-category__item-text {
          color: #fff;
          padding: 0 60px 0 30px; } }
      @media (min-width: 1366px) {
        #hp-category .hp-category__item-text, #hp-category-left .hp-category__item-text, #hp-category-right .hp-category__item-text {
          padding: 0 120px 0 30px; } }
    #hp-category .hp-category__item-title, #hp-category-left .hp-category__item-title, #hp-category-right .hp-category__item-title {
      margin-bottom: 20px;
      font-weight: 700;
      display: block;
      color: #fff;
      font-size: 26px;
      line-height: 32px; }
      @media (min-width: 576px) {
        #hp-category .hp-category__item-title, #hp-category-left .hp-category__item-title, #hp-category-right .hp-category__item-title {
          font-size: 36px;
          line-height: 44px; } }
      @media (min-width: 768px) {
        #hp-category .hp-category__item-title, #hp-category-left .hp-category__item-title, #hp-category-right .hp-category__item-title {
          color: #1a1b1c; } }
      @media (min-width: 1280px) {
        #hp-category .hp-category__item-title, #hp-category-left .hp-category__item-title, #hp-category-right .hp-category__item-title {
          color: #fff; } }
      #hp-category .hp-category__item-title:hover, #hp-category-left .hp-category__item-title:hover, #hp-category-right .hp-category__item-title:hover {
        color: #fff; }
        @media (min-width: 768px) {
          #hp-category .hp-category__item-title:hover, #hp-category-left .hp-category__item-title:hover, #hp-category-right .hp-category__item-title:hover {
            color: #1a1b1c; } }
        @media (min-width: 1280px) {
          #hp-category .hp-category__item-title:hover, #hp-category-left .hp-category__item-title:hover, #hp-category-right .hp-category__item-title:hover {
            color: #fff; } }
    #hp-category .hp-category__item-description, #hp-category-left .hp-category__item-description, #hp-category-right .hp-category__item-description {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 20px;
      color: #fff;
      display: none; }
      @media (min-width: 576px) {
        #hp-category .hp-category__item-description, #hp-category-left .hp-category__item-description, #hp-category-right .hp-category__item-description {
          display: block; } }
      @media (min-width: 768px) {
        #hp-category .hp-category__item-description, #hp-category-left .hp-category__item-description, #hp-category-right .hp-category__item-description {
          color: #1a1b1c; } }
      @media (min-width: 1280px) {
        #hp-category .hp-category__item-description, #hp-category-left .hp-category__item-description, #hp-category-right .hp-category__item-description {
          color: #fff; } }
      #hp-category .hp-category__item-description:hover, #hp-category-left .hp-category__item-description:hover, #hp-category-right .hp-category__item-description:hover {
        color: #fff; }
        @media (min-width: 768px) {
          #hp-category .hp-category__item-description:hover, #hp-category-left .hp-category__item-description:hover, #hp-category-right .hp-category__item-description:hover {
            color: #1a1b1c; } }
        @media (min-width: 1280px) {
          #hp-category .hp-category__item-description:hover, #hp-category-left .hp-category__item-description:hover, #hp-category-right .hp-category__item-description:hover {
            color: #fff; } }

#hp-category-right .hp-category__left {
  order: 1; }
  @media (min-width: 1280px) {
    #hp-category-right .hp-category__left {
      order: 2; } }

#hp-category-right .hp-category__right {
  order: 2; }
  @media (min-width: 1280px) {
    #hp-category-right .hp-category__right {
      order: 1; } }

#hp-category .swiper-hp-category.swiper-container,
#hp-category .swiper-hp-category-left.swiper-container,
#hp-category .swiper-hp-category-right.swiper-container, #hp-category-left .swiper-hp-category.swiper-container,
#hp-category-left .swiper-hp-category-left.swiper-container,
#hp-category-left .swiper-hp-category-right.swiper-container, #hp-category-right .swiper-hp-category.swiper-container,
#hp-category-right .swiper-hp-category-left.swiper-container,
#hp-category-right .swiper-hp-category-right.swiper-container {
  margin-bottom: 0; }
  #hp-category .swiper-hp-category.swiper-container .box-show-more,
  #hp-category .swiper-hp-category-left.swiper-container .box-show-more,
  #hp-category .swiper-hp-category-right.swiper-container .box-show-more, #hp-category-left .swiper-hp-category.swiper-container .box-show-more,
  #hp-category-left .swiper-hp-category-left.swiper-container .box-show-more,
  #hp-category-left .swiper-hp-category-right.swiper-container .box-show-more, #hp-category-right .swiper-hp-category.swiper-container .box-show-more,
  #hp-category-right .swiper-hp-category-left.swiper-container .box-show-more,
  #hp-category-right .swiper-hp-category-right.swiper-container .box-show-more {
    display: none; }
  #hp-category .swiper-hp-category.swiper-container .swiper-wrapper,
  #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper,
  #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper,
  #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper,
  #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper,
  #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper,
  #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper {
    margin-bottom: 0;
    padding: 50px 0; }
    #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide,
    #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide,
    #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide,
    #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide,
    #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide,
    #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide,
    #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide {
      padding: 0 15px; }
      @media (min-width: 1280px) {
        #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide,
        #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide,
        #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide,
        #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide,
        #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide,
        #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide,
        #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide {
          padding: 0 30px; } }
      #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product,
      #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product,
      #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product,
      #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product,
      #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product,
      #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product,
      #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product {
        display: block;
        border-radius: 16px;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
        position: relative;
        overflow: hidden;
        padding-bottom: 60px; }
        #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
        #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
        #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
        #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
        #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
        #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
        #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image {
          width: 100%;
          background-position: center;
          background-size: cover;
          margin: 0;
          background-repeat: no-repeat;
          padding-bottom: 75%; }
          @media (min-width: 1366px) {
            #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
            #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
            #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
            #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
            #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
            #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image,
            #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image {
              padding-bottom: 100%; } }
          #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image:after,
          #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image:after,
          #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image:after, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image:after,
          #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image:after,
          #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image:after, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image:after,
          #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image:after,
          #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: #ffffff;
            opacity: 0.2; }
          #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image a,
          #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image a,
          #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image a, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image a,
          #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image a,
          #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image a, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image a,
          #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image a,
          #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image a {
            position: relative;
            z-index: 3; }
          #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image img,
          #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image img,
          #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image img, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image img,
          #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image img,
          #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image img, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__image img,
          #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__image img,
          #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__image img {
            opacity: 0;
            position: absolute; }
        #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__text,
        #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__text,
        #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__text, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__text,
        #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__text,
        #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__text, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__text,
        #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__text,
        #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__text {
          width: 100%;
          height: 100%;
          background-color: inherit;
          padding: 0 15px;
          position: static; }
        #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__highlighted,
        #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__highlighted,
        #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__highlighted, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__highlighted,
        #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__highlighted,
        #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__highlighted, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__highlighted,
        #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__highlighted,
        #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__highlighted {
          display: none; }
        #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__title, #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__title a,
        #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__title,
        #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__title a,
        #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__title,
        #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__title a, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__title, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__title a,
        #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__title,
        #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__title a,
        #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__title,
        #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__title a, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__title, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__title a,
        #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__title,
        #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__title a,
        #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__title,
        #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__title a {
          font-size: 22px;
          line-height: 28px;
          margin: 0 0 3px; }
        #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__category,
        #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__category,
        #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__category, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__category,
        #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__category,
        #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__category, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__category,
        #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__category,
        #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__category {
          display: inline-flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          background-color: #349013;
          color: #fff;
          font-size: 11px;
          line-height: 11px;
          height: 24px;
          font-weight: 700;
          letter-spacing: 0.1em;
          padding: 0 5px;
          text-transform: uppercase;
          border-radius: 3px;
          margin-bottom: 15px;
          padding-top: 1px; }
        #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__price,
        #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__price,
        #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__price, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__price,
        #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__price,
        #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__price, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__price,
        #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__price,
        #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__price {
          position: absolute;
          bottom: 0;
          right: 0;
          margin: 0;
          background-color: #349013;
          border-radius: 0;
          border-top-left-radius: 16px; }
        #hp-category .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__actions,
        #hp-category .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__actions,
        #hp-category .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__actions, #hp-category-left .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__actions,
        #hp-category-left .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__actions,
        #hp-category-left .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__actions, #hp-category-right .swiper-hp-category.swiper-container .swiper-wrapper .swiper-slide .base-product__actions,
        #hp-category-right .swiper-hp-category-left.swiper-container .swiper-wrapper .swiper-slide .base-product__actions,
        #hp-category-right .swiper-hp-category-right.swiper-container .swiper-wrapper .swiper-slide .base-product__actions {
          display: none; }

#hp-articles-list {
  margin-bottom: 90px; }
  #hp-articles-list .hp-articles-list {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto; }
    #hp-articles-list .hp-articles-list__list {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      border-radius: 16px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0 0 99px rgba(0, 0, 0, 0.16); }
    #hp-articles-list .hp-articles-list__item {
      position: relative;
      width: 100%; }
      @media (min-width: 576px) {
        #hp-articles-list .hp-articles-list__item {
          width: 50%; } }
      @media (min-width: 768px) {
        #hp-articles-list .hp-articles-list__item {
          width: calc(100% / 3); } }
      #hp-articles-list .hp-articles-list__item-image img {
        display: block;
        max-width: 100%;
        height: auto;
        max-height: none; }
      #hp-articles-list .hp-articles-list__item-image-wrap {
        display: block;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
        @media (max-width: 575px) {
          #hp-articles-list .hp-articles-list__item-image-wrap {
            min-height: 300px; } }
        #hp-articles-list .hp-articles-list__item-image-wrap:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.4); }
        @media (max-width: 575px) {
          #hp-articles-list .hp-articles-list__item-image-wrap img {
            position: absolute;
            opacity: 0; } }
      #hp-articles-list .hp-articles-list__item-blogicons {
        display: none; }
      #hp-articles-list .hp-articles-list__item-category {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: #349013;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        height: 24px;
        font-weight: 700;
        letter-spacing: 0.1em;
        padding: 0 5px;
        text-transform: uppercase;
        border-radius: 3px;
        margin-bottom: 5px;
        padding-top: 1px; }
      #hp-articles-list .hp-articles-list__item-title {
        margin: 0;
        padding: 0;
        color: #fff; }
      #hp-articles-list .hp-articles-list__item-date {
        text-align: right;
        width: 100%;
        color: #fff;
        font-weight: 500;
        font-size: 12px; }
      #hp-articles-list .hp-articles-list__item-link {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-end;
        align-content: flex-end;
        width: 100%;
        height: 100%; }
      #hp-articles-list .hp-articles-list__item-text {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 15px;
        right: 0;
        color: #fff;
        padding: 0 15px; }
        @media (min-width: 576px) {
          #hp-articles-list .hp-articles-list__item-text {
            padding: 0 30px; } }
      #hp-articles-list .hp-articles-list__item-title {
        display: block;
        width: 100%;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 5px; }
      @media (max-width: 767px) {
        #hp-articles-list .hp-articles-list__item:nth-child(2) {
          width: 100%; } }
      @media (max-width: 767px) {
        #hp-articles-list .hp-articles-list__item:nth-child(2) .hp-articles-list__item-image-wrap {
          min-height: 300px; } }
      @media (max-width: 767px) {
        #hp-articles-list .hp-articles-list__item:nth-child(2) .hp-articles-list__item-image-wrap img {
          position: absolute;
          opacity: 0; } }
      #hp-articles-list .hp-articles-list__item:first-child {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center; }
        #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-category {
          margin-bottom: 15px; }
        #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-image-wrap {
          width: 100%;
          min-height: 260px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat; }
          @media (min-width: 768px) {
            #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-image-wrap {
              max-width: 40%; } }
          #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-image-wrap:after {
            display: none; }
          #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-image-wrap img {
            opacity: 0; }
        #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-title {
          color: #1a1b1c;
          font-size: 24px;
          line-height: 30px; }
          @media (min-width: 1200px) {
            #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-title {
              font-size: 30px;
              line-height: 38px; } }
        #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-description {
          color: #1a1b1c;
          margin: 0 0 5px;
          font-size: 14px;
          line-height: 22px; }
          @media (min-width: 1025px) {
            #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-description {
              font-size: 16px;
              line-height: 26px; } }
          @media (min-width: 1200px) {
            #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-description {
              font-size: 17px;
              line-height: 28px; } }
        #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-date {
          color: #1a1b1c; }
        #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-text {
          position: relative;
          width: 100%; }
          @media (max-width: 767px) {
            #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-text {
              padding: 0 15px;
              margin-top: 10px; } }
          @media (min-width: 768px) {
            #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-text {
              max-width: 60%; } }
          #hp-articles-list .hp-articles-list__item:first-child .hp-articles-list__item-text .hp-articles-list__item-link {
            display: block; }

#hp-product-list {
  margin-bottom: 90px; }
  #hp-product-list .hp-product-list .block {
    display: block;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto; }
  #hp-product-list .hp-product-list__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
  #hp-product-list .hp-product-list__item {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px; }
    @media (min-width: 576px) {
      #hp-product-list .hp-product-list__item {
        max-width: 50%; } }
    @media (min-width: 768px) {
      #hp-product-list .hp-product-list__item {
        max-width: calc(100% / 3); } }
    #hp-product-list .hp-product-list__item::marker {
      display: none; }
    #hp-product-list .hp-product-list__item:first-child, #hp-product-list .hp-product-list__item:last-child {
      max-width: 100%; }
      @media (min-width: 768px) {
        #hp-product-list .hp-product-list__item:first-child, #hp-product-list .hp-product-list__item:last-child {
          max-width: calc((100% / 3) * 2); } }
      @media (min-width: 576px) {
        #hp-product-list .hp-product-list__item:first-child .base-product, #hp-product-list .hp-product-list__item:last-child .base-product {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          align-content: center; } }
      @media (min-width: 576px) {
        #hp-product-list .hp-product-list__item:first-child .base-product__highlighted, #hp-product-list .hp-product-list__item:last-child .base-product__highlighted {
          display: none; } }
      @media (min-width: 576px) {
        #hp-product-list .hp-product-list__item:first-child .base-product__image, #hp-product-list .hp-product-list__item:last-child .base-product__image {
          max-width: 50%;
          margin: 0;
          padding: 0; } }
      @media (min-width: 576px) {
        #hp-product-list .hp-product-list__item:first-child .base-product__image img, #hp-product-list .hp-product-list__item:last-child .base-product__image img {
          position: static;
          opacity: 1; } }
      @media (min-width: 576px) {
        #hp-product-list .hp-product-list__item:first-child .base-product__text, #hp-product-list .hp-product-list__item:last-child .base-product__text {
          max-width: 50%;
          position: static;
          padding: 0 15px; } }
      @media (min-width: 1025px) {
        #hp-product-list .hp-product-list__item:first-child .base-product__text, #hp-product-list .hp-product-list__item:last-child .base-product__text {
          padding: 0 30px; } }
      @media (min-width: 576px) {
        #hp-product-list .hp-product-list__item:first-child .base-product__description, #hp-product-list .hp-product-list__item:last-child .base-product__description {
          display: block; } }
      @media (min-width: 576px) {
        #hp-product-list .hp-product-list__item:first-child .base-product__actions, #hp-product-list .hp-product-list__item:last-child .base-product__actions {
          display: none; } }
    #hp-product-list .hp-product-list__item .base-product {
      background-color: #fff;
      clear: both;
      border-radius: 16px;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      height: 100%; }
      #hp-product-list .hp-product-list__item .base-product__category {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: #349013;
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        height: 24px;
        font-weight: 700;
        letter-spacing: 0.1em;
        padding: 0 5px;
        text-transform: uppercase;
        border-radius: 3px;
        margin-bottom: 15px;
        padding-top: 1px; }
      #hp-product-list .hp-product-list__item .base-product__highlighted {
        display: none; }
      #hp-product-list .hp-product-list__item .base-product__image {
        width: 100%;
        margin: 0 0 15px;
        padding: 0;
        padding-bottom: 75%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat; }
        #hp-product-list .hp-product-list__item .base-product__image img {
          position: absolute;
          opacity: 0; }
      #hp-product-list .hp-product-list__item .base-product__title {
        margin-bottom: 5px; }
        #hp-product-list .hp-product-list__item .base-product__title, #hp-product-list .hp-product-list__item .base-product__title a {
          font-size: 18px; }
      #hp-product-list .hp-product-list__item .base-product__text {
        width: 100%;
        height: auto;
        padding: 0 15px 45px;
        position: static;
        min-height: auto; }
      #hp-product-list .hp-product-list__item .base-product__actions {
        display: none; }
      #hp-product-list .hp-product-list__item .base-product__price {
        margin: 0;
        bottom: 0;
        right: 0;
        border-radius: 0;
        border-top-left-radius: 16px; }
      @media (min-width: 576px) {
        #hp-product-list .hp-product-list__item .base-product__description {
          display: none; } }

#product .other-products__container {
  width: 100%;
  margin: 0;
  padding: 0px; }
  @media (min-width: 1200px) {
    #product .other-products__container {
      padding: 0 calc((100% - 1200px) / 2); } }

#product .swiper-other-products {
  padding: 50px 0; }
  #product .swiper-other-products .swiper-slide {
    padding: 0 15px; }
    #product .swiper-other-products .swiper-slide .base-product {
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.2); }
      #product .swiper-other-products .swiper-slide .base-product .thumbnail-container {
        margin: 0;
        padding: 0; }
      #product .swiper-other-products .swiper-slide .base-product__add {
        position: absolute;
        top: 0;
        right: 0;
        left: auto;
        bottom: auto; }
        #product .swiper-other-products .swiper-slide .base-product__add .add-to-cart {
          border-radius: 0;
          border-bottom-left-radius: 16px; }

#shop-open {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity .2s ease-in-out;
  z-index: 999999;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center; }
  #shop-open.open {
    visibility: visible;
    pointer-events: all;
    opacity: 1; }

.shop-open__wrapper {
  width: 100%;
  max-width: 800px;
  padding: 60px;
  box-shadow: 3px 6px 60px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px;
  background-image: url("../img/popup.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .shop-open__wrapper:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.35); }

.shop-open__logo {
  width: 180px;
  position: relative;
  z-index: 6; }
  .shop-open__logo:after {
    content: "";
    display: block;
    width: 150px;
    height: 150px;
    background-image: url("../img/logo-white.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.shop-open__text {
  max-width: calc(100% - 180px);
  width: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  text-align: center;
  padding: 30px 15px;
  position: relative;
  z-index: 6; }
  .shop-open__text P {
    font-size: 18px;
    font-weight: 600;
    color: #1a1b1c;
    margin-bottom: 30px; }
    .shop-open__text P:last-child {
      text-align: right; }

.shop-open__close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 3;
  cursor: pointer; }
  .shop-open__close span {
    height: 5px;
    width: 100%;
    display: block;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    transform-origin: 50% 50%;
    transform: rotate(45deg); }
    .shop-open__close span:first-child {
      transform: rotate(-45deg); }

@media (max-width: 1024px) {
  html.mobile-menu-open {
    overflow: hidden; }
    html.mobile-menu-open body #header .wrapper.brilliantMenu-top, html.mobile-menu-open body #header .wrapper {
      background-color: #fff !important; }
      html.mobile-menu-open body #header .wrapper.brilliantMenu-top:after, html.mobile-menu-open body #header .wrapper:after {
        box-shadow: none; }
    html.mobile-menu-open body #header:before {
      opacity: 1; } }

html body #header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  transition: background-color .2s ease-in-out;
  color: #1a1b1c; }
  html body #header:before {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1; }
  @media (min-width: 1025px) {
    html body #header {
      color: #fff;
      background-color: transparent; } }
  html body #header .blockcart .icon-fonts {
    font-size: 20px !important; }
  html body #header #mobile-lang {
    position: absolute;
    top: 22px;
    right: 15px;
    width: auto;
    float: none;
    padding: 0;
    display: none; }
    @media (min-width: 576px) {
      html body #header #mobile-lang {
        display: block; } }
    @media (min-width: 1025px) {
      html body #header #mobile-lang {
        display: none; } }
    html body #header #mobile-lang .language_selector {
      margin: 0;
      padding: 0; }
      html body #header #mobile-lang .language_selector img {
        width: 22px;
        height: 16px; }
  html body #header .language_selector .language-selector-wrapper {
    display: flex;
    flex-wrap: wrap; }
    html body #header .language_selector .language-selector-wrapper a {
      line-height: normal; }
    html body #header .language_selector .language-selector-wrapper img {
      display: block;
      margin-left: 15px;
      width: 22px;
      height: 16px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.16); }
      @media (min-width: 1025px) {
        html body #header .language_selector .language-selector-wrapper img {
          width: 17px;
          height: 13px; } }
  @media (max-width: 1024px) {
    html body #header .language_selector {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      margin-top: 30px; } }
  @media (max-width: 1024px) {
    html body #header .language_selector img {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
      width: 22px;
      height: 16px;
      height: auto; } }
  @media (max-width: 1024px) {
    html body #header .block-search {
      display: block;
      position: relative;
      width: 100%;
      margin-left: 0;
      padding: 0 15px 0 0;
      margin-bottom: 30px;
      transform: none;
      left: auto;
      top: auto;
      right: auto;
      bottom: auto;
      order: 2; } }
  html body #header .block-search > .search-icon {
    display: none; }
  @media (max-width: 1024px) {
    html body #header .block-search #search_widget {
      display: block;
      position: relative;
      width: 100%;
      margin-top: 0;
      transform: none;
      left: auto;
      top: auto;
      right: auto;
      bottom: auto; } }
  @media (max-width: 1024px) {
    html body #header .block-search form {
      margin-top: 30px; } }
  @media (max-width: 1024px) {
    html body #header .header-top .user-info-block {
      order: 4;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      margin-bottom: 30px; } }
  @media (max-width: 1024px) {
    html body #header .header-top .user-info-block .account_dropdown {
      display: none; } }
  @media (max-width: 1024px) {
    html body #header .header-top .user-info-block .user-info {
      display: block;
      width: 100%;
      box-shadow: none;
      padding: 0;
      margin: 0;
      position: relative;
      text-align: left; }
      html body #header .header-top .user-info-block .user-info a {
        display: block;
        text-align: left; } }
  html body #header #brilliant-menu-horizontal {
    width: 100%;
    flex-grow: 2;
    position: static;
    top: -65px;
    right: 0;
    opacity: 1;
    background-color: #fff;
    transition: opacity .2s ease-in-out, top .2s ease-in-out;
    padding-bottom: 45px; }
    @media (min-width: 1025px) {
      html body #header #brilliant-menu-horizontal {
        width: auto;
        background-color: transparent;
        position: relative;
        top: auto;
        right: auto;
        opacity: 1;
        pointer-events: all;
        padding-bottom: 0; } }
    @media (max-width: 1024px) {
      html body #header #brilliant-menu-horizontal {
        order: 5; } }
    html body #header #brilliant-menu-horizontal:before {
      display: none; }
      @media (max-width: 1024px) {
        html body #header #brilliant-menu-horizontal:before {
          content: "";
          position: fixed;
          background-color: rgba(0, 0, 0, 0.4);
          top: 100px;
          left: 0;
          bottom: 0;
          right: 0;
          display: block;
          opacity: 0;
          transition: opacity .2s ease-in-out, left .2s ease-in-out;
          pointer-events: none;
          display: block; } }
      @media (max-width: 429px) {
        html body #header #brilliant-menu-horizontal:before {
          top: 60px; } }
    html body #header #brilliant-menu-horizontal > ul {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      width: 100%;
      justify-content: center; }
      @media (min-width: 1025px) {
        html body #header #brilliant-menu-horizontal > ul {
          justify-content: flex-end;
          align-items: center;
          align-content: center; } }
      html body #header #brilliant-menu-horizontal > ul .menu-dropdown {
        box-shadow: 4px 12px 9px rgba(0, 0, 0, 0.15);
        transition: none;
        opacity: 0;
        transition: opacity .2s ease-in-out; }
        html body #header #brilliant-menu-horizontal > ul .menu-dropdown * {
          transition: opacity .2s ease-in-out; }
      html body #header #brilliant-menu-horizontal > ul .level-1 {
        width: 100%;
        display: block;
        margin-bottom: 30px; }
        @media (min-width: 1025px) {
          html body #header #brilliant-menu-horizontal > ul .level-1 {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            position: relative;
            width: auto;
            margin-bottom: 0; } }
        html body #header #brilliant-menu-horizontal > ul .level-1:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0; }
        html body #header #brilliant-menu-horizontal > ul .level-1 > .menu-dropdown {
          top: 60px;
          padding: 10px 0; }
          @media (max-width: 1024px) {
            html body #header #brilliant-menu-horizontal > ul .level-1 > .menu-dropdown {
              display: block;
              opacity: 1;
              box-shadow: none;
              width: 100%;
              position: relative;
              top: auto;
              left: auto;
              bottom: auto;
              right: auto;
              visibility: visible;
              text-align: left;
              transform: none;
              padding: 0; } }
        html body #header #brilliant-menu-horizontal > ul .level-1 > a {
          display: block;
          margin: 0;
          padding: 0;
          width: 100%;
          text-align: left;
          line-height: normal;
          margin-bottom: 20px;
          position: relative;
          z-index: 3; }
          @media (min-width: 1025px) {
            html body #header #brilliant-menu-horizontal > ul .level-1 > a {
              padding: 0 30px;
              width: auto;
              margin-bottom: 0;
              text-align: center;
              line-height: 60px;
              position: relative;
              z-index: 3; } }
          @media (max-width: 1024px) {
            html body #header #brilliant-menu-horizontal > ul .level-1 > a {
              font-size: 20px; } }
        html body #header #brilliant-menu-horizontal > ul .level-1 > .icon-drop-mobile {
          display: none; }
        html body #header #brilliant-menu-horizontal > ul .level-1.parent > .icon-drop-mobile {
          display: none !important;
          position: relative;
          float: none;
          height: 40px;
          right: 0;
          text-align: center;
          top: 0;
          line-height: 40px;
          width: 40px;
          z-index: 9; }
          html body #header #brilliant-menu-horizontal > ul .level-1.parent > .icon-drop-mobile:after {
            font-family: 'fontello';
            content: '\f107';
            font-weight: normal;
            font-style: normal;
            text-decoration: inherit;
            -webkit-font-smoothing: antialiased;
            font-size: 18px;
            cursor: pointer;
            color: #1a1b1c; }
          @media (min-width: 1025px) {
            html body #header #brilliant-menu-horizontal > ul .level-1.parent > .icon-drop-mobile {
              display: none !important; } }
      html body #header #brilliant-menu-horizontal > ul .level-2 > a {
        position: relative;
        z-index: 3; }
        @media (max-width: 1024px) {
          html body #header #brilliant-menu-horizontal > ul .level-2 > a {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
            html body #header #brilliant-menu-horizontal > ul .level-2 > a, html body #header #brilliant-menu-horizontal > ul .level-2 > a span {
              color: #1a1b1c;
              text-align: left;
              font-size: 16px;
              text-transform: none;
              font-weight: 600; } }
      html body #header #brilliant-menu-horizontal > ul .level-2 > .menu-dropdown {
        margin-left: 0;
        border-top-right-radius: 10px; }
        @media (max-width: 1024px) {
          html body #header #brilliant-menu-horizontal > ul .level-2 > .menu-dropdown {
            display: none; } }
      @media (max-width: 1024px) {
        html body #header #brilliant-menu-horizontal > ul .level-2.parent .icon-drop-mobile {
          display: none; } }
      @media (min-width: 1025px) {
        html body #header #brilliant-menu-horizontal > ul .level-1:hover > .menu-dropdown, html body #header #brilliant-menu-horizontal > ul .level-2:hover > .menu-dropdown, html body #header #brilliant-menu-horizontal > ul .level-3:hover > .menu-dropdown {
          opacity: 1; } }
  html body #header .container .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center; }
    @media (max-width: 1024px) {
      html body #header .container .row {
        justify-content: center; } }
  html body #header .topnav {
    display: none; }
  html body #header .header-banner {
    display: none; }
  html body #header .logo {
    position: absolute;
    opacity: 0;
    pointer-events: none; }
  html body #header .header__menu-column {
    float: none; }
    @media (max-width: 1024px) {
      html body #header .header__menu-column {
        position: fixed;
        top: 100px;
        left: -100%;
        max-width: 320px;
        bottom: 0;
        pointer-events: none;
        transition: left .2s ease-in-out, background-color .2s ease-in-out;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: center;
        width: 100%;
        overflow: visible;
        overflow-x: visible;
        overflow-y: scroll; } }
    @media (min-width: 1025px) {
      html body #header .header__menu-column {
        width: 100%;
        max-width: calc(100% - 230px);
        text-align: right;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: flex-end;
        position: static;
        position: relative; } }
    @media (max-width: 1024px) {
      html body #header .header__menu-column.mobile-open {
        pointer-events: all;
        left: 0;
        background-color: #fff;
        height: calc(100vh - 60px);
        top: 100px; } }
    @media (max-width: 429px) {
      html body #header .header__menu-column.mobile-open {
        top: 60px; } }
    @media (max-width: 1024px) {
      html body #header .header__menu-column.mobile-open #brilliant-menu-horizontal:before {
        opacity: 1;
        pointer-events: all;
        left: 320px; } }
  html body #header .header__logo-column {
    padding: 0 15px;
    max-width: 230px;
    width: 100%;
    float: none;
    pointer-events: none; }
    html body #header .header__logo-column, html body #header .header__logo-column a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: flex-start; }
      @media (max-width: 1024px) {
        html body #header .header__logo-column, html body #header .header__logo-column a {
          min-height: 60px; } }
    html body #header .header__logo-column a {
      pointer-events: all; }
  html body #header svg {
    max-width: 200px;
    height: auto;
    transition: fill .2s ease-in-out;
    fill: #1a1b1c; }
    @media (min-width: 1025px) {
      html body #header svg {
        fill: #fff; } }
  html body #header .brilliant-menu-horizontal {
    margin-top: 0; }
  html body #header .header-nav .topnav {
    background: transparent; }
  html body #header .header-container {
    position: static; }
    html body #header .header-container .header-top {
      padding: 0;
      position: static; }
    html body #header .header-container .logo {
      max-width: 200px; }
    html body #header .header-container .row {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      align-items: center; }
    html body #header .header-container .brilliant-menu-horizontal li.level-1 > a,
    html body #header .header-container .blockcart .header i,
    html body #header .header-container .icon-fonts,
    html body #header .header-container .icon-fonts:before,
    html body #header .header-container .block-search .search-icon:before {
      transition: color .2s ease-in-out;
      color: #1a1b1c; }
      @media (min-width: 1025px) {
        html body #header .header-container .brilliant-menu-horizontal li.level-1 > a,
        html body #header .header-container .blockcart .header i,
        html body #header .header-container .icon-fonts,
        html body #header .header-container .icon-fonts:before,
        html body #header .header-container .block-search .search-icon:before {
          color: #fff; } }
  html body #header .user-info-block {
    opacity: 1;
    transition: opacity .2s ease-in-out; }
    html body #header .user-info-block .icon-user {
      font-size: 20px; }
  html body #header .wrapper {
    transition: background-color .2s ease-in-out; }
    html body #header .wrapper:after {
      content: "";
      position: absolute;
      box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: opacity .2s ease-in-out;
      pointer-events: none; }
  html body #header .wrapper.brilliantMenu-top, html body #header .wrapper {
    padding: 0 !important;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.75) !important;
    backdrop-filter: blur(13px); }
    html body #header .wrapper.brilliantMenu-top svg, html body #header .wrapper svg {
      fill: #1a1b1c; }
    html body #header .wrapper.brilliantMenu-top .brilliant-menu-horizontal li.level-1 > a,
    html body #header .wrapper.brilliantMenu-top .blockcart .header i,
    html body #header .wrapper.brilliantMenu-top .icon-fonts,
    html body #header .wrapper.brilliantMenu-top .icon-fonts:before,
    html body #header .wrapper.brilliantMenu-top .block-search .search-icon:before,
    html body #header .wrapper.brilliantMenu-top .user-info-block,
    html body #header .wrapper.brilliantMenu-top .language_selector, html body #header .wrapper .brilliant-menu-horizontal li.level-1 > a,
    html body #header .wrapper .blockcart .header i,
    html body #header .wrapper .icon-fonts,
    html body #header .wrapper .icon-fonts:before,
    html body #header .wrapper .block-search .search-icon:before,
    html body #header .wrapper .user-info-block,
    html body #header .wrapper .language_selector {
      opacity: 1;
      color: #1a1b1c; }
    html body #header .wrapper.brilliantMenu-top:after, html body #header .wrapper:after {
      opacity: 1; }
  @media (min-width: 1025px) {
    html body #header:hover .wrapper.brilliantMenu-top, html body #header:hover .wrapper {
      background-color: #fff !important; }
      html body #header:hover .wrapper.brilliantMenu-top:after, html body #header:hover .wrapper:after {
        opacity: 1; }
    html body #header:hover svg {
      fill: #1a1b1c; }
    html body #header:hover .brilliant-menu-horizontal li.level-1 > a,
    html body #header:hover .blockcart .header i,
    html body #header:hover .icon-fonts,
    html body #header:hover .icon-fonts:before,
    html body #header:hover .block-search .search-icon:before,
    html body #header:hover .user-info-block,
    html body #header:hover .language_selector {
      color: #1a1b1c;
      opacity: 1; }
    html body #header:hover .wrapper.brilliantMenu-top .brilliant-menu-horizontal li.level-1 > a,
    html body #header:hover .wrapper.brilliantMenu-top .blockcart .header i,
    html body #header:hover .wrapper.brilliantMenu-top .icon-fonts,
    html body #header:hover .wrapper.brilliantMenu-top .icon-fonts:before,
    html body #header:hover .wrapper.brilliantMenu-top .block-search .search-icon:before,
    html body #header:hover .wrapper.brilliantMenu-top .user-info-block,
    html body #header:hover .wrapper.brilliantMenu-top .language_selector, html body #header:hover .wrapper .brilliant-menu-horizontal li.level-1 > a,
    html body #header:hover .wrapper .blockcart .header i,
    html body #header:hover .wrapper .icon-fonts,
    html body #header:hover .wrapper .icon-fonts:before,
    html body #header:hover .wrapper .block-search .search-icon:before,
    html body #header:hover .wrapper .user-info-block,
    html body #header:hover .wrapper .language_selector {
      color: #1a1b1c;
      opacity: 1; } }
  html body #header .brilliantMenu-top .menu-dropdown {
    top: 0; }

html body #footer {
  background-color: #1a1b1c;
  padding-top: 45px; }
  html body #footer__container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    float: none;
    align-items: center;
    align-content: center;
    justify-content: center; }
    @media (min-width: 1025px) {
      html body #footer__container {
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start; } }
  html body #footer .group-copyright {
    margin-bottom: 30px;
    margin-top: 45px;
    order: 4; }
  html body #footer .copyright {
    text-align: right; }
    html body #footer .copyright .block {
      margin-bottom: 0; }
  html body #footer .footer__logo {
    width: 100%;
    padding: 0;
    float: none;
    max-width: 200px;
    order: 3;
    margin: 0 0 30px; }
    @media (min-width: 576px) {
      html body #footer .footer__logo {
        margin: 0 30px 0 0;
        order: 2; } }
    @media (min-width: 1025px) {
      html body #footer .footer__logo {
        max-width: 300px;
        margin: 0;
        order: 1; } }
    html body #footer .footer__logo img {
      max-width: 100%; }
      @media (min-width: 1025px) {
        html body #footer .footer__logo img {
          max-width: 200px; } }
  html body #footer .footer__left {
    margin: 0;
    padding: 0;
    float: none;
    width: 100%;
    order: 2; }
    @media (min-width: 576px) {
      html body #footer .footer__left {
        order: 3;
        max-width: 185px; } }
    @media (min-width: 1025px) {
      html body #footer .footer__left {
        width: 100%;
        max-width: 250px;
        order: 2; } }
    @media (min-width: 1200px) {
      html body #footer .footer__left {
        max-width: 300px; } }
    html body #footer .footer__left .links.links-list .h3 {
      padding: 0;
      margin: 0;
      font-size: 0;
      line-height: 0; }
    html body #footer .footer__left .links.links-list .wrapper {
      float: none;
      margin: 0;
      padding: 0;
      width: 100%; }
    html body #footer .footer__left .links.links-list li {
      margin-bottom: 30px;
      line-height: 24px; }
    html body #footer .footer__left .links.links-list a {
      color: #fff;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      line-height: normal; }
    html body #footer .footer__left .block-social {
      text-align: left;
      width: 100%; }
  html body #footer .footer__right {
    margin: 0 0 30px;
    padding: 0;
    float: none;
    width: 100%;
    order: 1; }
    @media (min-width: 1025px) {
      html body #footer .footer__right {
        padding-left: 45px;
        max-width: calc(100% - 600px);
        order: 3;
        margin: 0; } }
    html body #footer .footer__right .block-categories {
      background-color: transparent;
      color: #fff;
      padding: 0; }
    html body #footer .footer__right .category-top-menu {
      display: flex;
      flex-wrap: wrap;
      background-color: transparent; }
      html body #footer .footer__right .category-top-menu .navbar-toggler, html body #footer .footer__right .category-top-menu .arrows {
        display: none; }
      html body #footer .footer__right .category-top-menu > li {
        width: 100%; }
        html body #footer .footer__right .category-top-menu > li:first-child {
          display: none; }
        html body #footer .footer__right .category-top-menu > li > .category-sub-menu {
          max-width: 100%;
          width: auto;
          float: none;
          display: flex;
          flex-wrap: wrap; }
          html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li {
            border: none;
            width: auto;
            float: none;
            width: 100%;
            margin-bottom: 30px; }
            @media (min-width: 768px) {
              html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li {
                max-width: 50%;
                margin-bottom: 0; } }
            html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li > .collapse {
              display: block; }
              html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li > .collapse li {
                border: none; }
            html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li a {
              color: #fff;
              padding-left: 0;
              text-transform: none; }
              html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li a:before {
                display: none; }
            html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li > a {
              font-size: 18px;
              line-height: 24px;
              font-weight: 700;
              margin-top: 0;
              padding: 0;
              margin-bottom: 30px; }
            html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li ul {
              padding: 0; }
              @media (min-width: 576px) and (max-width: 1024px) {
                html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li ul {
                  column-count: 2; } }
              html body #footer .footer__right .category-top-menu > li > .category-sub-menu > li ul > li > a {
                padding-top: 0;
                margin-top: 0; }

#category #wrapper .breadcrumb, #product #wrapper .breadcrumb, #module-smartblog-category #wrapper .breadcrumb, #module-smartblog-details #wrapper .breadcrumb, html #wrapper .breadcrumb, body #wrapper .breadcrumb {
  background-image: url("../img/breadcrumb.jpg");
  background-position: -9999999px -9999999px;
  background-repeat: no-repeat;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
  background-color: #1a1b1c;
  color: #fff;
  height: auto;
  padding-top: 120px; }
  @media (min-width: 430px) {
    #category #wrapper .breadcrumb, #product #wrapper .breadcrumb, #module-smartblog-category #wrapper .breadcrumb, #module-smartblog-details #wrapper .breadcrumb, html #wrapper .breadcrumb, body #wrapper .breadcrumb {
      padding-top: 160px; } }
  #category #wrapper .breadcrumb:after, #product #wrapper .breadcrumb:after, #module-smartblog-category #wrapper .breadcrumb:after, #module-smartblog-details #wrapper .breadcrumb:after, html #wrapper .breadcrumb:after, body #wrapper .breadcrumb:after {
    content: "";
    position: absolute;
    top: -45px;
    left: -45px;
    right: -45px;
    bottom: -45px;
    display: block;
    background-image: inherit;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(5px) brightness(65%); }
  #category #wrapper .breadcrumb__list, #category #wrapper .breadcrumb ol, #product #wrapper .breadcrumb__list, #product #wrapper .breadcrumb ol, #module-smartblog-category #wrapper .breadcrumb__list, #module-smartblog-category #wrapper .breadcrumb ol, #module-smartblog-details #wrapper .breadcrumb__list, #module-smartblog-details #wrapper .breadcrumb ol, html #wrapper .breadcrumb__list, html #wrapper .breadcrumb ol, body #wrapper .breadcrumb__list, body #wrapper .breadcrumb ol {
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center; }
  #category #wrapper .breadcrumb__container, #category #wrapper .breadcrumb .container, #product #wrapper .breadcrumb__container, #product #wrapper .breadcrumb .container, #module-smartblog-category #wrapper .breadcrumb__container, #module-smartblog-category #wrapper .breadcrumb .container, #module-smartblog-details #wrapper .breadcrumb__container, #module-smartblog-details #wrapper .breadcrumb .container, html #wrapper .breadcrumb__container, html #wrapper .breadcrumb .container, body #wrapper .breadcrumb__container, body #wrapper .breadcrumb .container {
    z-index: 3;
    position: relative;
    padding: 0 15px;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap; }
  #category #wrapper .breadcrumb__header, #product #wrapper .breadcrumb__header, #module-smartblog-category #wrapper .breadcrumb__header, #module-smartblog-details #wrapper .breadcrumb__header, html #wrapper .breadcrumb__header, body #wrapper .breadcrumb__header {
    width: 100%;
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 15px; }
    @media (min-width: 576px) {
      #category #wrapper .breadcrumb__header, #product #wrapper .breadcrumb__header, #module-smartblog-category #wrapper .breadcrumb__header, #module-smartblog-details #wrapper .breadcrumb__header, html #wrapper .breadcrumb__header, body #wrapper .breadcrumb__header {
        max-width: 75%; } }
  #category #wrapper .breadcrumb__description, #product #wrapper .breadcrumb__description, #module-smartblog-category #wrapper .breadcrumb__description, #module-smartblog-details #wrapper .breadcrumb__description, html #wrapper .breadcrumb__description, body #wrapper .breadcrumb__description {
    color: #fff;
    font-size: 15px;
    line-height: 23px; }
    @media (min-width: 576px) {
      #category #wrapper .breadcrumb__description, #product #wrapper .breadcrumb__description, #module-smartblog-category #wrapper .breadcrumb__description, #module-smartblog-details #wrapper .breadcrumb__description, html #wrapper .breadcrumb__description, body #wrapper .breadcrumb__description {
        font-size: 18px;
        line-height: 28px; } }
    @media (min-width: 768px) {
      #category #wrapper .breadcrumb__description, #product #wrapper .breadcrumb__description, #module-smartblog-category #wrapper .breadcrumb__description, #module-smartblog-details #wrapper .breadcrumb__description, html #wrapper .breadcrumb__description, body #wrapper .breadcrumb__description {
        max-width: 75%; } }
  #category #wrapper .breadcrumb li, #product #wrapper .breadcrumb li, #module-smartblog-category #wrapper .breadcrumb li, #module-smartblog-details #wrapper .breadcrumb li, html #wrapper .breadcrumb li, body #wrapper .breadcrumb li {
    display: flex;
    flex-wrap: wrap; }
    #category #wrapper .breadcrumb li a, #product #wrapper .breadcrumb li a, #module-smartblog-category #wrapper .breadcrumb li a, #module-smartblog-details #wrapper .breadcrumb li a, html #wrapper .breadcrumb li a, body #wrapper .breadcrumb li a {
      text-align: left;
      margin: 0 15px 0 0; }
    #category #wrapper .breadcrumb li:last-child, #product #wrapper .breadcrumb li:last-child, #module-smartblog-category #wrapper .breadcrumb li:last-child, #module-smartblog-details #wrapper .breadcrumb li:last-child, html #wrapper .breadcrumb li:last-child, body #wrapper .breadcrumb li:last-child {
      display: block;
      width: 100%;
      max-width: none; }
      #category #wrapper .breadcrumb li:last-child, #category #wrapper .breadcrumb li:last-child span, #category #wrapper .breadcrumb li:last-child a, #product #wrapper .breadcrumb li:last-child, #product #wrapper .breadcrumb li:last-child span, #product #wrapper .breadcrumb li:last-child a, #module-smartblog-category #wrapper .breadcrumb li:last-child, #module-smartblog-category #wrapper .breadcrumb li:last-child span, #module-smartblog-category #wrapper .breadcrumb li:last-child a, #module-smartblog-details #wrapper .breadcrumb li:last-child, #module-smartblog-details #wrapper .breadcrumb li:last-child span, #module-smartblog-details #wrapper .breadcrumb li:last-child a, html #wrapper .breadcrumb li:last-child, html #wrapper .breadcrumb li:last-child span, html #wrapper .breadcrumb li:last-child a, body #wrapper .breadcrumb li:last-child, body #wrapper .breadcrumb li:last-child span, body #wrapper .breadcrumb li:last-child a {
        font-size: 32px;
        line-height: 42px;
        text-transform: none;
        color: #fff; }
  #category #wrapper .breadcrumb li.breadcrumb__item, #product #wrapper .breadcrumb li.breadcrumb__item, #module-smartblog-category #wrapper .breadcrumb li.breadcrumb__item, #module-smartblog-details #wrapper .breadcrumb li.breadcrumb__item, html #wrapper .breadcrumb li.breadcrumb__item, body #wrapper .breadcrumb li.breadcrumb__item {
    display: flex;
    flex-wrap: wrap; }
    #category #wrapper .breadcrumb li.breadcrumb__item-link, #product #wrapper .breadcrumb li.breadcrumb__item-link, #module-smartblog-category #wrapper .breadcrumb li.breadcrumb__item-link, #module-smartblog-details #wrapper .breadcrumb li.breadcrumb__item-link, html #wrapper .breadcrumb li.breadcrumb__item-link, body #wrapper .breadcrumb li.breadcrumb__item-link {
      text-align: left;
      margin: 0 15px 0 0; }
    #category #wrapper .breadcrumb li.breadcrumb__item:last-child, #product #wrapper .breadcrumb li.breadcrumb__item:last-child, #module-smartblog-category #wrapper .breadcrumb li.breadcrumb__item:last-child, #module-smartblog-details #wrapper .breadcrumb li.breadcrumb__item:last-child, html #wrapper .breadcrumb li.breadcrumb__item:last-child, body #wrapper .breadcrumb li.breadcrumb__item:last-child {
      display: none; }

html body .pagination {
  margin-bottom: 45px; }
  html body .pagination .row .page-list {
    margin: 0 auto;
    float: none; }
    @media (max-width: 575px) {
      html body .pagination .row .page-list .page-item:nth-child(4), html body .pagination .row .page-list .page-item:nth-child(5), html body .pagination .row .page-list .page-item:nth-child(6) {
        display: none; } }

#header:hover .bottomnav {
  background-color: #000; }

#header .header-container .header-top .wrapper {
  position: fixed;
  width: 100%;
  z-index: 99;
  transition: top .2s ease-in-out, background-color .2s ease-in-out; }
  @media (min-width: 430px) {
    #header .header-container .header-top .wrapper {
      top: 40px; } }

@media (min-width: 1199px) {
  #header.menu-sticky .header-container .header-top .wrapper.brilliantMenu-top {
    top: 40px; } }

#header .header-nav {
  max-height: none;
  margin: 0;
  background-color: transparent;
  display: none; }
  @media (min-width: 430px) {
    #header .header-nav {
      display: block; } }

.bottomnav {
  background-color: rgba(0, 0, 0, 0.75);
  transition: background-color .2s ease-in-out;
  backdrop-filter: blur(13px);
  position: relative;
  z-index: 3; }
  .bottomnav:hover {
    color: #fff; }
  .bottomnav #contact-top {
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    float: none;
    align-items: center;
    font-weight: 500;
    min-height: 40px; }
    .bottomnav #contact-top .block ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0; }
      .bottomnav #contact-top .block ul li {
        font-weight: 700;
        margin-right: 30px; }
        .bottomnav #contact-top .block ul li:first-child {
          order: 2; }
          .bottomnav #contact-top .block ul li:first-child .media .media-body a:before {
            content: "";
            display: inline-block;
            height: 18px;
            width: 20px;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("../img/svg/mail.svg"); }
            @media (min-width: 768px) {
              .bottomnav #contact-top .block ul li:first-child .media .media-body a:before {
                margin-right: 10px; } }
        .bottomnav #contact-top .block ul li:nth-child(2) {
          font-family: 'Arial', sans-serif;
          order: 1; }
          @media (max-width: 767px) {
            .bottomnav #contact-top .block ul li:nth-child(2) {
              margin-right: 10px; } }
          .bottomnav #contact-top .block ul li:nth-child(2) .media .media-body a:before {
            content: "";
            display: inline-block;
            height: 20px;
            width: 20px;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("../img/svg/phone.svg"); }
            @media (min-width: 768px) {
              .bottomnav #contact-top .block ul li:nth-child(2) .media .media-body a:before {
                margin-right: 10px; } }
        .bottomnav #contact-top .block ul li .media {
          margin: 0;
          padding: 0; }
          .bottomnav #contact-top .block ul li .media .media-body {
            display: block;
            width: auto;
            overflow: visible; }
            .bottomnav #contact-top .block ul li .media .media-body a {
              color: #fff;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              align-content: center; }
              @media (max-width: 767px) {
                .bottomnav #contact-top .block ul li .media .media-body a {
                  font-size: 0; } }
    .bottomnav #contact-top .block-social ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .bottomnav #contact-top .block-social ul li {
        border: none;
        width: auto;
        height: auto;
        border-radius: 0;
        display: block;
        line-height: normal;
        margin: 0 10px 0 0; }
        .bottomnav #contact-top .block-social ul li:before {
          display: none; }
        .bottomnav #contact-top .block-social ul li a {
          width: auto;
          height: auto;
          color: #fff;
          position: static;
          font-size: 0; }
          .bottomnav #contact-top .block-social ul li a:before {
            content: "";
            display: inline-block;
            background-size: cover;
            background-repeat: no-repeat; }
          .bottomnav #contact-top .block-social ul li a, .bottomnav #contact-top .block-social ul li a * {
            text-indent: 0; }
    .bottomnav #contact-top .block-social .facebook a:before {
      width: 13px;
      height: 20px;
      background-image: url("../img/svg/fb.svg"); }
    .bottomnav #contact-top .block-social .instagram a:before {
      width: 20px;
      height: 22px;
      background-image: url("../img/svg/inst.svg"); }
    .bottomnav #contact-top .user-info-block {
      margin: 0 0 0 auto;
      justify-self: flex-end;
      align-self: center; }
      .bottomnav #contact-top .user-info-block .account_dropdown {
        display: none; }
      .bottomnav #contact-top .user-info-block .dropdown-menu {
        position: static;
        background-color: transparent;
        transform: none;
        padding: 0;
        margin: 0;
        box-shadow: none;
        min-width: auto;
        font-size: 16px;
        font-weight: 700; }
        .bottomnav #contact-top .user-info-block .dropdown-menu a {
          color: #fff; }
    .bottomnav #contact-top .block,
    .bottomnav #contact-top .block-social {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0;
      padding: 0;
      width: auto; }

#category #search_filters_wrapper {
  display: none !important; }

#category #wrapper > .container {
  margin-top: 45px !important; }
  @media (min-width: 1025px) {
    #category #wrapper > .container {
      margin-top: 100px !important; } }

#category #wrapper #left-column {
  padding: 0 15px !important;
  width: 100%;
  margin-top: 0; }
  @media (min-width: 1025px) {
    #category #wrapper #left-column {
      max-width: 300px; } }
  #category #wrapper #left-column .featured-products {
    display: none; }
    @media (min-width: 1025px) {
      #category #wrapper #left-column .featured-products {
        display: block; } }
  #category #wrapper #left-column .category-top-menu {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    padding: 15px; }
    @media (max-width: 575px) {
      #category #wrapper #left-column .category-top-menu {
        max-height: 200px;
        overflow-y: scroll; } }
    #category #wrapper #left-column .category-top-menu > li:first-child {
      display: none; }
  #category #wrapper #left-column .block-categories {
    margin-top: 0;
    margin-bottom: 30px; }
  #category #wrapper #left-column .block-categories > .category-top-menu > li > .category-sub-menu > li {
    border: none;
    width: 100%; }
    @media (max-width: 1024px) {
      #category #wrapper #left-column .block-categories > .category-top-menu > li > .category-sub-menu > li .navbar-toggler {
        display: none; } }
    @media (max-width: 1024px) {
      #category #wrapper #left-column .block-categories > .category-top-menu > li > .category-sub-menu > li > .collapse {
        display: block; } }
    @media (min-width: 768px) and (max-width: 1024px) {
      #category #wrapper #left-column .block-categories > .category-top-menu > li > .category-sub-menu > li > .collapse .category-sub-menu {
        column-count: 3; } }
    @media (min-width: 576px) and (max-width: 767px) {
      #category #wrapper #left-column .block-categories > .category-top-menu > li > .category-sub-menu > li > .collapse .category-sub-menu {
        column-count: 2; } }
    @media (max-width: 1024px) {
      #category #wrapper #left-column .block-categories > .category-top-menu > li > .category-sub-menu > li > .collapse .category-sub-menu li[data-depth="1"] {
        float: none;
        border-top: none; } }
    @media (max-width: 1024px) {
      #category #wrapper #left-column .block-categories > .category-top-menu > li > .category-sub-menu > li > .collapse .category-sub-menu li[data-depth="1"] .arrows {
        display: none; } }
    @media (max-width: 1024px) {
      #category #wrapper #left-column .block-categories > .category-top-menu > li > .category-sub-menu > li > .collapse .category-sub-menu li[data-depth="1"] .category-sub-link {
        float: none; } }

#category #wrapper #content-wrapper {
  padding: 0 15px !important;
  width: 100%; }
  @media (min-width: 1025px) {
    #category #wrapper #content-wrapper {
      max-width: calc(100% - 300px); } }

#category #wrapper .product-miniature .thumbnail-container {
  background-color: transparent;
  margin-bottom: 0;
  position: static; }

#category #wrapper #products .list-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0 0 45px; }
  #category #wrapper #products .list-tools__sort {
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    padding: 0;
    position: relative;
    z-index: 9; }
    #category #wrapper #products .list-tools__sort .product-sort {
      width: 100%;
      border-radius: 16px; }
      #category #wrapper #products .list-tools__sort .product-sort:after {
        content: "";
        position: absolute;
        display: block;
        pointer-events: none;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: transparent;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        transition: bottom .2s ease-in-out;
        border-radius: 16px; }
      #category #wrapper #products .list-tools__sort .product-sort__icon {
        margin-top: 0; }
      #category #wrapper #products .list-tools__sort .product-sort__title {
        font-style: normal;
        font-weight: 700;
        color: #349013 !important;
        background-color: #fff;
        position: relative;
        z-index: 3;
        border: none;
        height: 45px;
        border-radius: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        text-align: left;
        width: 100%; }
      #category #wrapper #products .list-tools__sort .product-sort__list {
        display: block;
        left: 0;
        right: 0;
        z-index: 1;
        height: 0;
        padding-bottom: 0;
        transition: height .2s ease-in-out, padding-bottom .2s ease-in-out;
        overflow: hidden;
        box-shadow: none;
        margin: 0;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px; }
        #category #wrapper #products .list-tools__sort .product-sort__list-wrap {
          height: 100%;
          overflow-y: auto; }
        #category #wrapper #products .list-tools__sort .product-sort__list-item {
          height: auto;
          padding: 10px 22px 10px 15px;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          align-items: center;
          align-content: center;
          color: #1a1b1c;
          font-weight: 600; }
          #category #wrapper #products .list-tools__sort .product-sort__list-item:first-child {
            padding-top: 0; }
          #category #wrapper #products .list-tools__sort .product-sort__list-item:last-child {
            padding-bottom: 0; }
          #category #wrapper #products .list-tools__sort .product-sort__list-item:hover {
            color: #349013;
            background-color: #fff; }
        #category #wrapper #products .list-tools__sort .product-sort__list .simplebar-track.simplebar-vertical {
          right: 12px; }
        #category #wrapper #products .list-tools__sort .product-sort__list .simplebar-scrollbar::before {
          opacity: 0;
          background-color: #349013; }
        #category #wrapper #products .list-tools__sort .product-sort__list:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          left: auto;
          bottom: 0;
          width: 22px;
          background-color: #fff;
          display: block; }
      #category #wrapper #products .list-tools__sort .product-sort.open .simplebar-scrollbar::before {
        opacity: 1; }
      #category #wrapper #products .list-tools__sort .product-sort.open:after {
        bottom: -150px; }
      #category #wrapper #products .list-tools__sort .product-sort.open .product-sort__list {
        padding-bottom: 20px;
        height: 150px; }
  #category #wrapper #products .list-tools__display {
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    display: none; }
    @media (min-width: 576px) {
      #category #wrapper #products .list-tools__display {
        display: flex; } }
    #category #wrapper #products .list-tools__display-item {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
      height: 45px;
      width: 45px;
      background-color: #fff;
      border-radius: 8px;
      transition: background-color .2s ease-in-out, box-shadow .2s ease-in-out; }
      #category #wrapper #products .list-tools__display-item a, #category #wrapper #products .list-tools__display-item i {
        color: rgba(0, 0, 0, 0.6);
        transition: color .2s ease-in-out; }
      #category #wrapper #products .list-tools__display-item a {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 100%; }
      #category #wrapper #products .list-tools__display-item--grid {
        margin-right: 15px; }
      #category #wrapper #products .list-tools__display-item.selected {
        background-color: #349013; }
        #category #wrapper #products .list-tools__display-item.selected, #category #wrapper #products .list-tools__display-item.selected a, #category #wrapper #products .list-tools__display-item.selected i {
          color: #fff; }
      #category #wrapper #products .list-tools__display-item:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }
  #category #wrapper #products .list-tools__counter {
    margin: 0;
    padding: 0;
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 30px;
    display: none; }
    @media (min-width: 768px) {
      #category #wrapper #products .list-tools__counter {
        display: flex; } }
    #category #wrapper #products .list-tools__counter p {
      font-size: 14px;
      font-weight: 600; }

#category #wrapper #products .base-product {
  height: 100%;
  box-shadow: 3px 5px 17px rgba(0, 0, 0, 0.2); }
  #category #wrapper #products .base-product__add {
    margin: 0;
    left: auto;
    right: 0;
    top: 0;
    bottom: auto;
    border-radius: 0; }
    #category #wrapper #products .base-product__add .add-to-cart {
      border-radius: 0;
      border-bottom-left-radius: 16px;
      margin-left: 0;
      background-color: #349013; }

@media (min-width: 576px) {
  #category #wrapper #products .product_list.product-list-default.list .base-product .thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    #category #wrapper #products .product_list.product-list-default.list .base-product .thumbnail-container .base-product__image {
      width: 100%;
      max-width: 35%;
      padding-bottom: 0;
      margin-bottom: 0; }
      #category #wrapper #products .product_list.product-list-default.list .base-product .thumbnail-container .base-product__image img {
        position: relative; }
    #category #wrapper #products .product_list.product-list-default.list .base-product .thumbnail-container .base-product__text {
      width: 100%;
      max-width: 65%;
      padding: 0 90px 15px 15px; } }
    @media (min-width: 576px) and (min-width: 768px) {
      #category #wrapper #products .product_list.product-list-default.list .base-product .thumbnail-container .base-product__text {
        padding: 0 90px 45px 15px; } }

@media (min-width: 576px) {
    #category #wrapper #products .product_list.product-list-default.list .base-product .thumbnail-container .base-product__title {
      color: #fff; } }

#category #wrapper .product-list__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px; }

#category #wrapper .product-list__column {
  margin-bottom: 30px; }

#category #wrapper .product-list.grid .product-list__column {
  width: 100%; }

html #product #wrapper {
  margin-top: 0; }
  html #product #wrapper > .container {
    max-width: none;
    width: 100%;
    padding: 0;
    margin: 0; }
    html #product #wrapper > .container > .row {
      margin: 0; }
  html #product #wrapper #content {
    background-color: #fff;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    overflow: hidden; }
  html #product #wrapper #product-modal .modal-dialog {
    max-width: 730px;
    padding: 0 15px; }
    html #product #wrapper #product-modal .modal-dialog .modal-body {
      background-color: #fff; }
  html #product #wrapper .single-product .input-group.bootstrap-touchspin {
    height: 50px; }
  html #product #wrapper .single-product .product-quantity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center; }
    html #product #wrapper .single-product .product-quantity .qty {
      margin: 0 10px; }
    html #product #wrapper .single-product .product-quantity .qty,
    html #product #wrapper .single-product .product-quantity .product-prices,
    html #product #wrapper .single-product .product-quantity .add {
      margin-bottom: 10px; }
  html #product #wrapper .single-product__zoom {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #fff;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity .2s ease-in-out; }
    html #product #wrapper .single-product__zoom i {
      font-size: 80px;
      color: #fff; }
    html #product #wrapper .single-product__zoom:hover {
      opacity: 1; }
      html #product #wrapper .single-product__zoom:hover i {
        color: #fff; }
  html #product #wrapper .single-product__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    margin-bottom: 100px;
    margin-top: 45px; }
    @media (min-width: 1025px) {
      html #product #wrapper .single-product__container {
        margin-top: 100px; } }
  html #product #wrapper .single-product__image {
    width: 100%;
    max-width: 540px;
    margin: 0 auto 30px; }
    @media (min-width: 900px) {
      html #product #wrapper .single-product__image {
        max-width: 35%;
        margin: 0; } }
    @media (min-width: 1025px) {
      html #product #wrapper .single-product__image {
        max-width: 540px; } }
  html #product #wrapper .single-product__text {
    width: 100%; }
    @media (min-width: 900px) {
      html #product #wrapper .single-product__text {
        padding-left: 30px;
        max-width: 65%; } }
    @media (min-width: 1025px) {
      html #product #wrapper .single-product__text {
        max-width: calc(100% - 540px); } }
  html #product #wrapper .single-product__name {
    margin: 0;
    padding: 0;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 36px; }
    @media (min-width: 768px) {
      html #product #wrapper .single-product__name {
        font-size: 36px;
        line-height: 42px; } }
    @media (min-width: 1280px) {
      html #product #wrapper .single-product__name {
        margin-bottom: 30px;
        font-size: 42px;
        line-height: 48px; } }
  html #product #wrapper .single-product__actions {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px; }
  html #product #wrapper .single-product__description {
    margin-bottom: 20px; }
    @media (min-width: 1280px) {
      html #product #wrapper .single-product__description {
        margin-bottom: 30px; } }
    html #product #wrapper .single-product__description, html #product #wrapper .single-product__description p {
      font-size: 15px;
      line-height: 23px; }
      @media (min-width: 768px) {
        html #product #wrapper .single-product__description, html #product #wrapper .single-product__description p {
          font-size: 16px;
          line-height: 24px; } }
      @media (min-width: 1280px) {
        html #product #wrapper .single-product__description, html #product #wrapper .single-product__description p {
          font-size: 18px;
          line-height: 26px; } }
    html #product #wrapper .single-product__description p {
      margin-bottom: 15px; }
      html #product #wrapper .single-product__description p:last-child {
        margin-bottom: 0; }
  html #product #wrapper .single-product__price {
    height: 50px;
    margin: 0;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 15px;
    background-color: #349013; }
    html #product #wrapper .single-product__price .current-price {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center; }
    html #product #wrapper .single-product__price * {
      vertical-align: middle; }
  html #product #wrapper .single-product__bottom p {
    margin-bottom: 15px; }
  html #product #wrapper .single-product__bottom .product-quantities {
    display: none; }
  html #product #wrapper .single-product__footer {
    width: 100%; }
  html #product #wrapper .single-product__add-to-cart {
    background-color: #349013;
    width: 100%;
    height: 50px;
    width: 200px;
    margin: 0; }
    html #product #wrapper .single-product__add-to-cart[disabled] {
      opacity: 0.3; }
    html #product #wrapper .single-product__add-to-cart:hover {
      color: #fff; }
  html #product #wrapper .single-product__form {
    width: 100%; }
  html #product #wrapper .single-product__additional .social-sharing {
    padding: 0;
    margin: 0;
    border: none; }
    html #product #wrapper .single-product__additional .social-sharing span {
      text-transform: none; }
  html #product #wrapper .single-product__additional .googleplus {
    display: none; }
  html #product #wrapper #product-availability {
    margin-left: 0;
    display: block;
    width: 100%;
    margin-top: 0;
    font-size: 13px; }

#my-account #wrapper .links .link-item {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
  transition: background-color .2s ease-in-out, color .2s ease-in-out; }
  @media (min-width: 576px) {
    #my-account #wrapper .links .link-item {
      min-height: 300px; } }
  #my-account #wrapper .links .link-item i {
    transition: color .2s ease-in-out; }
  @media (min-width: 1025px) {
    #my-account #wrapper .links .link-item:hover {
      background-color: #349013; }
      #my-account #wrapper .links .link-item:hover, #my-account #wrapper .links .link-item:hover i {
        color: #fff; } }

#module-smartblog-category #wrapper > .container > .row {
  display: flex;
  flex-wrap: wrap; }
  #module-smartblog-category #wrapper > .container > .row #left-column {
    order: 2;
    padding: 0 15px;
    float: none;
    width: 100%;
    max-width: 25%;
    margin: 0;
    display: none; }
    @media (min-width: 1025px) {
      #module-smartblog-category #wrapper > .container > .row #left-column {
        display: block; } }
  #module-smartblog-category #wrapper > .container > .row #content-wrapper {
    order: 1;
    padding: 0 15px !important;
    float: none;
    display: block;
    width: 100%;
    margin: 0; }
    @media (min-width: 1025px) {
      #module-smartblog-category #wrapper > .container > .row #content-wrapper {
        max-width: 75%; } }

#module-smartblog-category #smartblogcat {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  #module-smartblog-category #smartblogcat .sdsarticleCat {
    width: 100%;
    display: block;
    margin: 0 0 30px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    background-color: #fff; }
    #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center; }
      #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .articleContentImg {
        border-radius: 0;
        overflow: hidden;
        width: 100%;
        background-size: cover;
        background-position: center; }
        @media (min-width: 576px) {
          #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .articleContentImg {
            max-width: 35%; } }
        #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .articleContentImg .sdsarticleHeader {
          display: none; }
        #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .articleContentImg:after {
          content: "";
          display: block;
          padding-bottom: 56.25%; }
          @media (min-width: 576px) {
            #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .articleContentImg:after {
              padding-bottom: 100%; } }
          @media (min-width: 768px) {
            #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .articleContentImg:after {
              padding-bottom: 75%; } }
        #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .articleContentImg img {
          border-radius: 0;
          position: absolute;
          opacity: 0; }
      #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .sdsarticle-des {
        width: 100%;
        padding: 15px; }
        @media (min-width: 576px) {
          #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .sdsarticle-des {
            max-width: 65%; } }
        @media (min-width: 768px) {
          #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .sdsarticle-des {
            padding: 30px; } }
        #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .sdsarticle-des .title_block {
          padding: 0;
          margin-bottom: 15px;
          font-size: 24px;
          line-height: 30px; }
          @media (min-width: 576px) {
            #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .sdsarticle-des .title_block {
              font-size: 20px;
              line-height: 24px; } }
          @media (min-width: 768px) {
            #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .sdsarticle-des .title_block {
              font-size: 24px;
              line-height: 30px; } }
        #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .sdsarticle-des .sdsreadMore {
          margin-top: 15px;
          text-align: right; }
          #module-smartblog-category #smartblogcat .sdsarticleCat .articleContent .sdsarticle-des .sdsreadMore a {
            font-size: 11px; }

#module-smartblog-category #left-column .block-categories {
  margin-top: 0;
  margin-bottom: 30px;
  display: none; }

#module-smartblog-details #wrapper > .container > .row {
  display: flex;
  flex-wrap: wrap; }
  #module-smartblog-details #wrapper > .container > .row #left-column {
    order: 2;
    padding: 0 15px;
    float: none;
    width: 100%;
    max-width: 25%;
    margin: 0;
    display: none; }
    @media (min-width: 1025px) {
      #module-smartblog-details #wrapper > .container > .row #left-column {
        display: block; } }
  #module-smartblog-details #wrapper > .container > .row #content-wrapper {
    order: 1;
    padding: 0 15px !important;
    float: none;
    display: block;
    width: 100%;
    margin: 0; }
    @media (min-width: 1025px) {
      #module-smartblog-details #wrapper > .container > .row #content-wrapper {
        max-width: 75%; } }

#module-smartblog-details #wrapper #left-column {
  padding: 0 15px !important;
  margin: 0; }
  #module-smartblog-details #wrapper #left-column .block-categories {
    display: none; }

#module-smartblog-details #wrapper #content-wrapper {
  padding: 0 15px !important;
  margin: 0; }
  #module-smartblog-details #wrapper #content-wrapper .sdsarticleHeaderContent {
    background-color: #fff;
    padding: 30px 15px; }
    @media (min-width: 576px) {
      #module-smartblog-details #wrapper #content-wrapper .sdsarticleHeaderContent {
        padding: 45px 30px; } }
  #module-smartblog-details #wrapper #content-wrapper .articleContent {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    border-radius: 16px; }
  #module-smartblog-details #wrapper #content-wrapper .sdsarticleHeader {
    left: -15px;
    right: -15px;
    bottom: -30px;
    width: calc(100% + 30px);
    background-color: #1a1b1c;
    position: relative; }
    @media (min-width: 576px) {
      #module-smartblog-details #wrapper #content-wrapper .sdsarticleHeader {
        width: calc(100% + 60px);
        left: -30px;
        right: -30px;
        bottom: -45px; } }
    #module-smartblog-details #wrapper #content-wrapper .sdsarticleHeader > span {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      font-size: 0; }
      #module-smartblog-details #wrapper #content-wrapper .sdsarticleHeader > span i {
        margin-right: 2px;
        font-size: 14px; }
      #module-smartblog-details #wrapper #content-wrapper .sdsarticleHeader > span span {
        margin-right: 15px;
        font-size: 12px; }
        @media (min-width: 576px) {
          #module-smartblog-details #wrapper #content-wrapper .sdsarticleHeader > span span {
            margin-right: 30px; } }
  #module-smartblog-details #wrapper #content-wrapper #respond {
    background-color: #fff;
    color: #1a1b1c;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    padding: 15px; }
    @media (min-width: 380px) {
      #module-smartblog-details #wrapper #content-wrapper #respond {
        padding: 30px; } }
    @media (min-width: 576px) {
      #module-smartblog-details #wrapper #content-wrapper #respond {
        padding: 45px; } }
    #module-smartblog-details #wrapper #content-wrapper #respond #submitComment {
      background-color: #349013; }
