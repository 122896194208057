html body {
    #footer {
    background-color: $color-font-main;
    padding-top: 45px;
    &__container {
        max-width: 1200px;
        padding: 0 15px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        float: none;
        align-items: center;
        align-content: center;
        justify-content: center;
        @media(min-width: 1025px) {
            align-items: flex-start;
            align-content: flex-start;
            justify-content: flex-start;
        }
    }
    .group-copyright {
        margin-bottom: 30px;
        margin-top: 45px;
        order: 4;
    }
    .copyright {
        text-align: right;
        .block {
            margin-bottom: 0;
        }
    }
    .footer {
        &__logo {
            width: 100%;
            padding: 0;
            float: none;
            max-width: 200px;
            order: 3;
            margin: 0 0 30px;
            @media(min-width: 576px) {
                margin: 0 30px 0 0;
                order: 2;
            }
            @media(min-width: 1025px) {
                max-width: 300px;
                margin: 0;
                order: 1;
            }
            img {
                max-width: 100%;
                @media(min-width: 1025px) {
                    max-width: 200px;
                }
            }
        }
        &__left {
            margin: 0;
            padding: 0;
            float: none;
            width: 100%;
            order: 2;
            @media(min-width: 485px) {
            }
            @media(min-width: 576px) {
                order: 3;
                max-width: 185px;
            }
            @media(min-width: 1025px) {
                width: 100%;
                max-width: 250px;
                order: 2;
            }
            @media(min-width: 1200px) {
                max-width: 300px;
            }
            .links.links-list {
                .h3 {
                    padding: 0;
                    margin: 0;
                    font-size: 0;
                    line-height: 0;
                }
                .wrapper {
                    float: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                }
                li {
                    margin-bottom: 30px;
                    line-height: 24px;
                }
                a {
                    color: $color-white;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 700;
                    line-height: normal;
                }
            }
            .block-social {
                text-align: left;
                width: 100%;
            }
        }
        &__right {
            margin: 0 0 30px;
            padding: 0;
            float: none;
            width: 100%;
            order: 1;
            @media(min-width: 1025px) {
                padding-left: 45px;
                max-width: calc(100% - 600px);
                order: 3;
                margin: 0;
            }
            .block-categories {
                background-color: transparent;
                color: $color-white;
                padding: 0;
            }
            .category-top-menu {
                display: flex;
                flex-wrap: wrap;
                background-color: transparent;
                .navbar-toggler, .arrows {
                    display: none;
                }
                & > li {
                    width: 100%;
                    &:first-child {
                        display: none;
                    }
                    & > .category-sub-menu {
                        max-width: 100%;
                        width: auto;
                        float: none;
                        display: flex;
                        flex-wrap: wrap;

                        & > li {
                            border: none;
                            width: auto;
                            float: none;
                            width: 100%;
                            margin-bottom: 30px;
                            @media(min-width: 768px) {
                                max-width: 50%;
                                margin-bottom: 0;
                            }
                            & > .collapse {
                                display: block;
                                li {
                                    border: none;
                                }
                            }
                            a {
                                color: $color-white;
                                padding-left: 0;
                                text-transform: none;
                                &:before {
                                    display: none;
                                }
                            }
                            & > a {
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: 700;
                                margin-top: 0;
                                padding: 0;
                                margin-bottom: 30px;
                            }
                            ul {
                                padding: 0;
                                @media(min-width: 576px) and (max-width: 1024px) {
                                    column-count: 2;
                                }
                                & > li {
                                    & > a {
                                        padding-top: 0;
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
}
