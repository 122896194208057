#product {
    .other-products {
        &__container {
            width: 100%;
            margin: 0;
            padding: 0px;
            @media(min-width: 1200px) {
                padding: 0 calc((100% - 1200px) / 2);
            }
        }
    }
    .swiper-other-products {
        padding: 50px 0;
        .swiper-slide {
            padding: 0 15px;
            .base-product {
                box-shadow: 0 0 12px rgba(0,0,0,0.2);
                .thumbnail-container {
                    margin: 0;
                    padding: 0;
                }
                &__add {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: auto;
                    bottom: auto;
                    .add-to-cart {
                        border-radius: 0;
                        border-bottom-left-radius: 16px;
                    }
                }
            }
        }
    }
}
