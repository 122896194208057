#hp-category, #hp-category-left, #hp-category-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    position: relative;
    margin-bottom: 90px;
    overflow: hidden;
    .hp-category {
        margin: 0;
        padding: 0;
        &__left {
            width: 100%;
            @media(min-width: 1280px) {
                max-width: 35% !important;
            }
        }
        &__right {
            width: 100%;
            @media(min-width: 1280px) {
                max-width: 65% !important;
            }
        }
        &__item {
            &-wrap {
                position: relative;
                margin: 0;
            }
            &-content {

            }
            &-btn {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                width: 100%;
                max-width: 200px;
                min-height: 45px;
                background-color: $color-green;
                color: $color-white;
                border-radius: 4px;
                overflow: hidden;
                position: relative;
                transition: color .2s ease-in-out, box-shadow .2s ease-in-out;
                &:after {
                    content: "";
                    left: 0;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    background-color: $color-white;
                    right: 100%;
                    transition: right .2s ease-in-out;
                }
                &-span {
                    position: relative;
                    z-index: 3;
                    transition: color .2s ease-in-out;
                }
                &:hover {
                    @media(min-width: 1025px) {
                        box-shadow: 0 0 15px rgba(0,0,0,0.2);
                        .hp-category__item-btn-span {
                            color: $color-green;
                        }
                        &:after {
                            right: 0;
                        }
                    }
                }
            }
            &-image {
                float: none;
                padding: 0;
                background-repeat: no-repeat;
                background-position: -999999999px -999999999px;
                overflow: hidden;
                position: relative;
                min-height: 250px;
                @media(min-width: 576px) {
                    min-height: 300px;
                }
                @media(min-width: 768px) {
                    overflow: visible;
                    padding-bottom: 0;
                    position: static;
                    min-height: auto;
                }
                &:after {
                    content: "";
                    background-position: right;
                    background-image: inherit;
                    background-repeat: no-repeat;
                    display: block;
                    padding-bottom: 0%;
                    position: absolute;
                    top: -30px;
                    bottom: -30px;
                    left: -30px;
                    right: -30px;
                    background-size: cover;
                    filter: blur(13px);
                    @media(min-width: 768px) {
                        position: static;
                        padding-bottom: 40%;
                        background-size: 50%;
                        filter: none;
                    }
                    @media(min-width: 1025px) {
                        padding-bottom: 30%;
                    }
                    @media(min-width: 1280px) {
                        padding-bottom: 100%;
                        background-size: cover;
                        background-position: center;
                        border-radius: 0;
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    z-index: 2;
                    background-color: rgba(0,0,0,0.5);
                    @media(min-width: 768px) {
                        background-color: transparent;
                    }
                    @media(min-width: 1280px) {
                        background-color: rgba(0,0,0,0.5);
                    }
                }
                img {
                    max-width: 100%;
                    width: 100%;
                    display: block;
                    height: auto;
                    position: absolute;
                    opacity: 0;
                }
            }
            &-link {
                width: 100%;
                display: block;
                @media(min-width: 1280px) {
                    color: $color-white;
                }
            }
            &-text {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                padding: 0 30px;
                justify-content: center;
                text-align: center;
                z-index: 5;
                @media(min-width: 768px) {
                    text-align: left;
                    justify-content: flex-start;
                    padding: 0 calc(50% + 15px) 0 15px;
                }
                @media(min-width: 1280px) {
                    color: $color-white;
                    padding: 0 60px 0 30px;
                }
                @media(min-width: 1366px) {
                    padding: 0 120px 0 30px;
                }
            }
            &-title {
                margin-bottom: 20px;
                font-weight: 700;
                display: block;
                color: $color-white;
                font-size: 26px;
                line-height: 32px;
                @media(min-width: 576px) {
                    font-size: 36px;
                    line-height: 44px;
                }
                @media(min-width: 768px) {
                    color: $color-font-main;
                }
                @media(min-width: 1280px) {
                    color: $color-white;
                }
                &:hover {
                    color: $color-white;
                    @media(min-width: 768px) {
                        color: $color-font-main;
                    }
                    @media(min-width: 1280px) {
                        color: $color-white;
                    }
                }
            }
            &-description {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 20px;
                color: $color-white;
                display: none;
                @media(min-width: 576px) {
                    display: block;
                }
                @media(min-width: 768px) {
                    color: $color-font-main;
                }
                @media(min-width: 1280px) {
                    color: $color-white;
                }
                &:hover {
                    color: $color-white;
                    @media(min-width: 768px) {
                        color: $color-font-main;
                    }
                    @media(min-width: 1280px) {
                        color: $color-white;
                    }
                }
            }
        }
    }
}
#hp-category-right {
    .hp-category__left {
        order: 1;
        @media(min-width: 1280px) {
            order: 2;
        }
    }
    .hp-category__right {
        order: 2;
        @media(min-width: 1280px) {
            order: 1;
        }
    }
}
