html {
    &.mobile-menu-open {
        @media(max-width: 1024px) {
            overflow: hidden;
            body {
                #header {
                    .wrapper.brilliantMenu-top, .wrapper {
                        background-color: $color-white !important;
                        &:after {
                            box-shadow: none;
                        }
                    }
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
    body {
        #header {
            position: fixed;
            left: 0;
            top: 0;
            z-index: 99;
            width: 100%;
            transition: background-color .2s ease-in-out;
            color: $color-font-main;
            &:before {
                content: "";
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                transition: opacity .2s ease-in-out;
                pointer-events: none;
                background-color: rgba(0,0,0,0.3);
                z-index: -1;
            }
            @media(min-width: 1025px) {
                color: $color-white;
                background-color: transparent;
            }
            .blockcart {
                .icon-fonts {
                    font-size: 20px !important;
                }
            }
            #mobile-lang {
                position: absolute;
                top: 22px;
                right: 15px;
                width: auto;
                float: none;
                padding: 0;
                display: none;
                @media(min-width: 576px) {
                    display: block;
                }
                @media(min-width: 1025px) {
                    display: none;
                }
                .language_selector {
                    margin: 0;
                    padding: 0;
                    img {
                        width: 22px;
                        height: 16px;
                    }
                }
            }
            .language_selector {
                .language-selector-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    a {
                        line-height: normal;
                    }
                    img {
                        display: block;
                        margin-left: 15px;
                        width: 22px;
                        height: 16px;
                        box-shadow: 0 0 3px rgba(0,0,0,0.16);
                        @media(min-width: 1025px) {
                            width: 17px;
                            height: 13px;
                        }
                    }
                }
                @media(max-width: 1024px) {
                    position: relative;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    margin-top: 30px;
                }
                img {
                    @media(max-width: 1024px) {
                        box-shadow: 0 0 3px rgba(0,0,0,0.16);
                        width: 22px;
                        height: 16px;
                        height: auto;
                    }
                }
            }
            .block-search {
                @media(max-width: 1024px) {
                    display: block;
                    position: relative;
                    width: 100%;
                    margin-left: 0;
                    padding: 0 15px 0 0;
                    margin-bottom: 30px;
                    transform: none;
                    left: auto;
                    top: auto;
                    right: auto;
                    bottom: auto;
                    order: 2;
                }
                & > .search-icon {
                    display: none;
                }
                #search_widget {
                    @media(max-width: 1024px) {
                        display: block;
                        position: relative;
                        width: 100%;
                        margin-top: 0;
                        transform: none;
                        left: auto;
                        top: auto;
                        right: auto;
                        bottom: auto;
                    }
                }
                form {
                    @media(max-width: 1024px) {
                        margin-top: 30px;
                    }
                }
            }
            .header-top {

                .user-info-block {
                    @media(max-width: 1024px) {
                        order: 4;
                        display: block;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        position: relative;
                        margin-bottom: 30px;
                    }
                    .account_dropdown {
                        @media(max-width: 1024px) {
                            display: none;
                        }
                    }
                    .user-info {
                        @media(max-width: 1024px) {
                            display: block;
                            width: 100%;
                            box-shadow: none;
                            padding: 0;
                            margin: 0;
                            position: relative;
                            text-align: left;
                            a {
                                display: block;
                                text-align: left;
                            }
                        }
                    }
                }
            }
            #brilliant-menu-horizontal {
                width: 100%;
                flex-grow: 2;
                position: static;
                top: -65px;
                right: 0;
                opacity: 1;
                background-color: $color-white;
                transition: opacity .2s ease-in-out, top .2s ease-in-out;
                padding-bottom: 45px;
                @media(min-width: 1025px) {
                    width: auto;
                    background-color: transparent;
                    position: relative;
                    top: auto;
                    right: auto;
                    opacity: 1;
                    pointer-events: all;
                    padding-bottom: 0;
                }
                @media(max-width: 1024px) {
                    order: 5;
                }
                &:before {
                    display: none;
                    @media(max-width: 1024px) {
                        content: "";
                        position: fixed;
                        background-color: rgba(0,0,0,0.4);
                        top: 100px;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        display: block;
                        opacity: 0;
                        transition: opacity .2s ease-in-out, left .2s ease-in-out;
                        pointer-events: none;
                        display: block;
                    }
                    @media(max-width: 429px) {
                        top: 60px;
                    }
                }
                & > ul {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    align-content: flex-start;
                    width: 100%;
                    justify-content: center;
                    @media(min-width: 1025px) {
                        justify-content: flex-end;
                        align-items: center;
                        align-content: center;
                    }
                    .menu-dropdown {
                        box-shadow: 4px 12px 9px rgba(0, 0, 0, 0.15);
                        transition: none;
                        opacity: 0;
                        transition: opacity .2s ease-in-out;
                        * {
                            transition: opacity .2s ease-in-out;
                        }
                    }
                    .level-1 {
                        width: 100%;
                        display: block;
                        margin-bottom: 30px;
                        @media(min-width: 1025px) {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            align-content: center;
                            position: relative;
                            width: auto;
                            margin-bottom: 0;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                        }
                        & > .menu-dropdown {
                            top: 60px;
                            padding: 10px 0;
                            @media(max-width: 1024px) {
                                display: block;
                                opacity: 1;
                                box-shadow: none;
                                width: 100%;
                                position: relative;
                                top: auto;
                                left: auto;
                                bottom: auto;
                                right: auto;
                                visibility: visible;
                                text-align: left;
                                transform: none;
                                padding: 0;
                            }
                        }
                        & > a {
                            display: block;
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            text-align: left;
                            line-height: normal;
                            margin-bottom: 20px;
                            position: relative;
                            z-index: 3;
                            @media(min-width: 1025px) {
                                padding: 0 30px;
                                width: auto;
                                margin-bottom: 0;
                                text-align: center;
                                line-height: 60px;
                                position: relative;
                                z-index: 3;
                            }
                            @media(max-width: 1024px) {
                                font-size: 20px;
                            }
                        }
                        & > .icon-drop-mobile {
                            display: none;
                        }
                        &.parent > .icon-drop-mobile {
                            display: none !important;
                            position: relative;
                            float: none;
                            height: 40px;
                            right: 0;
                            text-align: center;
                            top: 0;
                            line-height: 40px;
                            width: 40px;
                            z-index: 9;
                            &:after {
                                font-family: 'fontello';
                                content: '\f107';
                                font-weight: normal;
                                font-style: normal;
                                text-decoration: inherit;
                                -webkit-font-smoothing: antialiased;
                                font-size: 18px;
                                cursor: pointer;
                                color: $color-font-main;
                            }
                            @media(min-width: 1025px) {
                                display: none !important;
                            }
                        }
                    }
                    .level-2 {
                        & > a {
                            position: relative;
                            z-index: 3;
                            @media(max-width: 1024px) {
                                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                                &, span {
                                    color: $color-font-main;
                                    text-align: left;
                                    font-size: 16px;
                                    text-transform: none;
                                    font-weight: 600;
                                }
                            }
                        }
                        & > .menu-dropdown {
                            margin-left: 0;
                            border-top-right-radius: 10px;
                            @media(max-width: 1024px) {
                                display: none;
                            }
                        }
                        &.parent {
                            .icon-drop-mobile {
                                @media(max-width: 1024px) {
                                    display: none;
                                }
                            }
                        }
                    }
                    .level-1, .level-2, .level-3 {
                        &:hover {
                            @media(min-width: 1025px) {
                                & > .menu-dropdown {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            .container {
                .row {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    @media(max-width: 1024px) {
                        justify-content: center;
                    }
                }
            }
            .topnav {
                display: none;
            }
            .header-banner {
                display: none;
            }
            .logo {
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }
            .header__menu-column {
                float: none;
                @media(max-width: 1024px) {
                    position: fixed;
                    top: 100px;
                    left: -100%;
                    max-width: 320px;
                    bottom: 0;
                    pointer-events: none;
                    transition: left .2s ease-in-out, background-color .2s ease-in-out;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    align-content: flex-start;
                    justify-content: center;
                    width: 100%;
                    overflow: visible;
                    overflow-x: visible;
                    overflow-y: scroll;
                }
                @media(min-width: 1025px) {
                    width: 100%;
                    max-width: calc(100% - 230px);
                    text-align: right;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    justify-content: flex-end;
                    position: static;
                    position: relative;
                }
                &.mobile-open {
                    @media(max-width: 1024px) {
                        pointer-events: all;
                        left: 0;
                        background-color: $color-white;
                        height: calc(100vh - 60px);
                        top: 100px;
                    }
                    @media(max-width: 429px) {
                        top: 60px;
                    }
                    #brilliant-menu-horizontal {
                        &:before {
                            @media(max-width: 1024px) {
                                opacity: 1;
                                pointer-events: all;
                                left: 320px;
                            }
                        }
                    }
                }
            }
            .header__logo-column {
                padding: 0 15px;
                max-width: 230px;
                width: 100%;
                float: none;
                pointer-events: none;
                &, a {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    justify-content: flex-start;
                    @media(max-width: 1024px) {
                        min-height: 60px;
                    }
                }
                a {
                    pointer-events: all;
                }
            }
            svg {
                max-width: 200px;
                height: auto;
                transition: fill .2s ease-in-out;
                fill: $color-font-main;
                @media(min-width: 1025px) {
                    fill: $color-white;
                }
            }
            .brilliant-menu-horizontal {
                margin-top: 0;
            }
            .header-nav .topnav {
                background: transparent;
            }
            .header-container {
                position: static;
                .header-top {
                    padding: 0;
                    position: static;
                    .wrapper {
                    }
                }
                .logo {
                    max-width: 200px;
                }
                .row {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    align-items: center;
                }
                .brilliant-menu-horizontal li.level-1 > a,
                .blockcart .header i,
                .icon-fonts,
                .icon-fonts:before,
                .block-search .search-icon:before {
                    transition: color .2s ease-in-out;
                    color: $color-font-main;
                    @media(min-width: 1025px) {
                        color: $color-white;
                    }
                }
            }
            .user-info-block {
                opacity: 1;
                transition: opacity .2s ease-in-out;
                .icon-user {
                    font-size: 20px;
                }
            }
            .wrapper {
                transition: background-color .2s ease-in-out;
                &:after {
                    content: "";
                    position: absolute;
                    box-shadow: 0 0 99px rgba(0,0,0,0.16);
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    opacity: 0;
                    transition: opacity .2s ease-in-out;
                    pointer-events: none;
                }
            }
            .wrapper.brilliantMenu-top, .wrapper {
                padding: 0 !important;
                box-shadow: none;
                background-color: rgba(255,255,255,0.75) !important;
                backdrop-filter: blur(13px);
                svg {
                    fill: $color-font-main;
                }
                .brilliant-menu-horizontal li.level-1 > a,
                .blockcart .header i,
                .icon-fonts,
                .icon-fonts:before,
                .block-search .search-icon:before,
                .user-info-block,
                .language_selector {
                    opacity: 1;
                    color: $color-font-main;
                }
                &:after {
                    opacity: 1;
                }
            }
            &:hover,  {
                @media(min-width: 1025px) {
                    .wrapper.brilliantMenu-top, .wrapper {
                        background-color: $color-white !important;
                        &:after {
                            opacity: 1;
                        }
                    }
                    svg {
                        fill: $color-font-main;
                    }
                    .brilliant-menu-horizontal li.level-1 > a,
                    .blockcart .header i,
                    .icon-fonts,
                    .icon-fonts:before,
                    .block-search .search-icon:before,
                    .user-info-block,
                    .language_selector {
                        color: $color-font-main;
                        opacity: 1;
                    }
                    .wrapper.brilliantMenu-top, .wrapper {
                        .brilliant-menu-horizontal li.level-1 > a,
                        .blockcart .header i,
                        .icon-fonts,
                        .icon-fonts:before,
                        .block-search .search-icon:before,
                        .user-info-block,
                        .language_selector {
                            color: $color-font-main;
                            opacity: 1;
                        }
                    }
                }

            }
            .brilliantMenu-top  {
                .menu-dropdown {
                    top: 0;
                }
            }

        }
    }
}
