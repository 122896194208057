html {
    #product {
        #wrapper {
            margin-top: 0;
            & > .container {
                max-width: none;
                width: 100%;
                padding: 0;
                margin: 0;
                & > .row {
                    margin: 0;
                }
            }
            #content {
                background-color: $color-white;
                box-shadow: 0 0 99px rgba(0,0,0,0.16);
                border-radius: 16px;
                overflow: hidden;
            }
            #product-modal {
                .modal-dialog {
                    max-width: 730px;
                    padding: 0 15px;
                    .modal-body {
                        background-color: $color-white;
                    }
                }
            }
            .single-product {
                .input-group.bootstrap-touchspin {
                    height: 50px;
                }
                .product-quantity {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    .qty {
                        margin: 0 10px;
                    }
                    .qty,
                    .product-prices,
                    .add {
                        margin-bottom: 10px;
                    }
                }
                &__zoom {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: transparent;
                    box-shadow: none;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    color: $color-white;
                    opacity: 0;
                    background-color: rgba(0,0,0,0.3);
                    transition: opacity .2s ease-in-out;
                    i {
                        font-size: 80px;
                        color: $color-white;
                    }
                    &:hover {
                        opacity: 1;
                        i {
                            color: $color-white;
                        }
                    }
                }
                &__container {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    align-content: flex-start;
                    justify-content: space-between;
                    margin-bottom: 100px;
                    margin-top: 45px;
                    @media(min-width: 1025px) {
                        margin-top: 100px;
                    }
                }
                &__image {
                    width: 100%;
                    max-width: 540px;
                    margin: 0 auto 30px;
                    @media(min-width: 900px) {
                        max-width: 35%;
                        margin: 0;
                    }
                    @media(min-width: 1025px) {
                        max-width: 540px;
                    }
                }
                &__text {
                    width: 100%;
                    @media(min-width: 900px) {
                        padding-left: 30px;
                        max-width: 65%;
                    }
                    @media(min-width: 1025px) {
                        max-width: calc(100% - 540px);
                    }
                }
                &__name {
                    margin: 0;
                    padding: 0;
                    font-weight: 700;
                    margin-bottom: 20px;
                    font-size: 30px;
                    line-height: 36px;
                    @media(min-width: 768px) {
                        font-size: 36px;
                        line-height: 42px;
                    }
                    @media(min-width: 1280px) {
                        margin-bottom: 30px;
                        font-size: 42px;
                        line-height: 48px;
                    }
                }
                &__actions {
                    margin-top: 0;
                    display: flex;
                    flex-wrap: wrap;
                    padding-bottom: 15px;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                    margin-bottom: 30px;
                }
                &__description {
                    margin-bottom: 20px;
                    @media(min-width: 1280px) {
                        margin-bottom: 30px;
                    }
                    &, p {
                        font-size: 15px;
                        line-height: 23px;
                        @media(min-width: 768px) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                        @media(min-width: 1280px) {
                            font-size: 18px;
                            line-height: 26px;
                        }
                    }
                    p {
                        margin-bottom: 15px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                &__price {
                    height: 50px;
                    margin: 0;
                    display: inline-flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    padding: 0 15px;
                    background-color: $color-green;
                    .current-price {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                    }
                    * {
                        vertical-align: middle;
                    }
                }
                &__bottom {
                    // display: none;
                    p {
                        margin-bottom: 15px;
                    }
                    .product-quantities {
                        display: none;
                    }
                }
                &__footer {
                    width: 100%;
                }
                &__add-to-cart {
                    background-color: $color-green;
                    width: 100%;
                    height: 50px;
                    width: 200px;
                    margin: 0;
                    &[disabled] {
                        opacity: 0.3;
                    }
                    &:hover {
                        color: $color-white;
                    }
                }
                &__form {
                    width: 100%;
                }
                &__additional {
                    .social-sharing {
                        padding: 0;
                        margin: 0;
                        border: none;
                        span {
                            text-transform: none;
                        }
                    }
                    .googleplus {
                        display: none;
                    }
                }
            }
            #product-availability {
                margin-left: 0;
                display: block;
                width: 100%;
                margin-top: 0;
                font-size: 13px;
            }
        }
    }
}
