#category {
    #search_filters_wrapper {
        display: none !important;
    }
    #wrapper {
        & > .container {
            margin-top: 45px !important;
            @media(min-width: 1025px) {
                margin-top: 100px !important;
            }
        }
        #left-column {
            padding: 0 15px !important;
            width: 100%;
            margin-top: 0;
            @media(min-width: 1025px) {
                max-width: 300px;
            }
            .featured-products {
                display: none;
                @media(min-width: 1025px) {
                    display: block;
                }
            }
            .category-top-menu {
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
                border-radius: 16px;
                padding: 15px;
                @media(max-width: 575px) {
                    max-height: 200px;
                    overflow-y: scroll;
                }
                & > li {
                    &:first-child {
                        display: none;
                    }
                }
            }
            .block-categories {
                margin-top: 0;
                margin-bottom: 30px;
            }
            .block-categories > .category-top-menu > li > .category-sub-menu > li {
                border: none;
                width: 100%;
                .navbar-toggler {
                    @media(max-width: 1024px) {
                        display: none;
                    }
                }
                & > .collapse {
                    @media(max-width: 1024px) {
                        display: block;
                    }
                    .category-sub-menu {
                        @media(min-width: 768px) and (max-width: 1024px) {
                            column-count: 3;
                        }
                        @media(min-width: 576px) and (max-width: 767px) {
                            column-count: 2;
                        }
                        li[data-depth="1"] {
                            @media(max-width: 1024px) {
                                float: none;
                                border-top: none;
                            }
                            .arrows {
                                @media(max-width: 1024px) {
                                    display: none;
                                }
                            }
                            .category-sub-link {
                                @media(max-width: 1024px) {
                                    float: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        #content-wrapper {
            padding: 0 15px !important;
            width: 100%;
            @media(min-width: 1025px) {
                max-width: calc(100% - 300px);
            }
        }
        .product-miniature {
            .thumbnail-container {
                background-color: transparent;
                margin-bottom: 0;
                position: static;
            }
        }
        #products {
            .list-tools {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                margin: 0 0 45px;
                &__sort {
                    width: 100%;
                    max-width: 300px;
                    background-color: $color-white;
                    padding: 0;
                    position: relative;
                    z-index: 9;
                    .product-sort {
                        width: 100%;
                        border-radius: 16px;
                        &:after {
                            content: "";
                            position: absolute;
                            display: block;
                            pointer-events: none;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-color: transparent;
                            box-shadow: 0 0 30px rgba(0,0,0,0.16);
                            transition: bottom .2s ease-in-out;
                            border-radius: 16px;
                        }
                        &__icon {
                            margin-top: 0;
                        }
                        &__title {
                            font-style: normal;
                            font-weight: 700;
                            color: $color-green !important;
                            background-color: $color-white;
                            position: relative;
                            z-index: 3;
                            border: none;
                            height: 45px;
                            border-radius: 16px;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            align-content: center;
                            justify-content: space-between;
                            text-align: left;
                            width: 100%;
                        }
                        &__list {
                            display: block;
                            left: 0;
                            right: 0;
                            z-index: 1;
                            height: 0;
                            padding-bottom: 0;
                            transition: height .2s ease-in-out, padding-bottom .2s ease-in-out;
                            overflow: hidden;
                            box-shadow: none;
                            margin: 0;
                            border-bottom-left-radius: 16px;
                            border-bottom-right-radius: 16px;
                            &-wrap {
                                height: 100%;
                                overflow-y: auto;
                            }
                            &-item {
                                height: auto;
                                padding: 10px 22px 10px 15px;
                                display: flex;
                                flex-wrap: wrap;
                                width: 100%;
                                align-items: center;
                                align-content: center;
                                color: $color-font-main;
                                font-weight: 600;
                                &:first-child {
                                    padding-top: 0;
                                }
                                &:last-child {
                                    padding-bottom: 0;
                                }
                                &:hover {
                                    color: $color-green;
                                    background-color: $color-white;
                                }
                            }
                            .simplebar-track.simplebar-vertical {
                                right: 12px;
                            }
                            .simplebar-scrollbar::before {
                                opacity: 0;
                                background-color: $color-green;
                            }
                            &:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                left: auto;
                                bottom: 0;
                                width: 22px;
                                background-color: $color-white;
                                display: block;
                            }
                        }
                        &.open {
                            .simplebar-scrollbar::before {
                                opacity: 1;
                            }
                            &:after {
                                bottom: -150px;
                            }
                            .product-sort {
                                &__list {
                                    padding-bottom: 20px;
                                    height: 150px;
                                }
                            }
                        }
                    }
                }
                &__display {
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    display: none;
                    @media(min-width: 576px) {
                        display: flex;
                    }
                    &-item {
                        box-shadow: 0 0 15px rgba(0,0,0,0.16);
                        height: 45px;
                        width: 45px;
                        background-color: $color-white;
                        border-radius: 8px;
                        transition: background-color .2s ease-in-out, box-shadow .2s ease-in-out;
                        a, i {
                            color: rgba(0,0,0,0.6);
                            transition: color .2s ease-in-out;
                        }
                        a {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                        }
                        &--grid {
                            margin-right: 15px;
                        }
                        &.selected {
                            background-color: $color-green;
                            &, a, i {
                                color: $color-white;
                            }
                        }
                        &:hover {
                            box-shadow: 0 0 15px rgba(0,0,0,0.3);
                        }
                    }
                }
                &__counter {
                    margin: 0;
                    padding: 0;
                    justify-self: flex-end;
                    margin-left: auto;
                    margin-right: 30px;
                    display: none;
                    @media(min-width: 768px) {
                        display: flex;
                    }
                    p {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
            .base-product {
                height: 100%;
                box-shadow: 3px 5px 17px rgba(0, 0, 0, 0.2);
                &__add {
                    margin: 0;
                    left: auto;
                    right: 0;
                    top: 0;
                    bottom: auto;
                    border-radius: 0;
                    .add-to-cart {
                        border-radius: 0;
                        border-bottom-left-radius: 16px;
                        margin-left: 0;
                        background-color: $color-green;
                    }
                }
            }
            .product_list.product-list-default.list {
                .base-product {
                    .thumbnail-container {
                        @media(min-width: 576px) {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            .base-product__image {
                                width: 100%;
                                max-width: 35%;
                                padding-bottom: 0;
                                margin-bottom: 0;
                                img {
                                    position: relative;
                                }
                            }
                            .base-product__text {
                                width: 100%;
                                max-width: 65%;
                                padding: 0 90px 15px 15px;
                                @media(min-width: 768px) {
                                    padding: 0 90px 45px 15px;
                                }
                            }
                            .base-product__title {
                                color: $color-white;
                            }
                        }
                    }
                }
            }
        }
        .product-list {
            &__row {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 30px;
            }
            &__column {
                margin-bottom: 30px;
            }
            &.grid {
                .product-list__column {
                    width: 100%;
                    @media(min-width: 576px) {

                    }
                    .base-product {

                    }
                }
            }
        }
    }
}
