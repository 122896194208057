.base-product {
    background-color: $color-white;
    clear: both;
    border-radius: 16px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    &__category {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: $color-green;
        color: $color-white;
        font-size: 11px;
        line-height: 11px;
        height: 24px;
        font-weight: 700;
        letter-spacing: 0.1em;
        padding: 0 5px;
        text-transform: uppercase;
        border-radius: 3px;
        margin-bottom: 5px;
        padding-top: 1px;
    }
    &__add {
        margin: 0;
        left: auto;
        right: 0;
        top: 0;
        bottom: auto;
        border-radius: 0;
        .add-to-cart {
            border-radius: 0;
            border-bottom-left-radius: 16px;
            margin-left: 0;
            background-color: $color-green;
        }
    }
    &__highlighted {
        display: none;
    }
    &__reviews {
        margin: 0 0 20px;
    }
    &__image {
        width: 100%;
        margin: 0 0 15px;
        padding: 0;
        padding-bottom: 75%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        img {
            position: absolute;
            opacity: 0;
        }
    }
    &__title {
        margin-bottom: 5px;
        color: $color-font-main;
        overflow: visible !important;
        &, a {
            font-size: 18px;
        }
    }
    &__text {
        width: 100%;
        height: auto;
        padding: 0 15px 45px;
    }
    &__actions {
        display: none;
    }
    &__price {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
        background-color: $color-green;
        color: $color-white;
        border-radius: 0;
        border-top-left-radius: 16px;
        font-size: 16px;
        font-weight: 600;
        padding: 7px 10px;
    }
    &__description {
        font-size: 16px;
        line-height: 24px;
    }
}
#wrapper {
    .base-product {
        &__add {
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;
            .add-to-cart {
                border-radius: 0;
                border-bottom-left-radius: 16px;
            }
        }
        .thumbnail-container {
            margin-bottom: 0;
        }
    }
    #left-column {
        .product-list {
            &__column {
                margin-bottom: 0;
            }
        }
    }
}
#category, #module-smartblog-category, #module-smartblog-details {
    #wrapper {
        #left-column {
            .base-product {
                margin-bottom: 30px;
            }
            .product-list {
                &__column {
                    margin-bottom: 0;
                }
            }
        }
    }
}
