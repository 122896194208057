#my-account {
    #wrapper {
        .links {
            .link-item {
                box-shadow: 0 0 30px rgba(0,0,0,0.16);
                border-radius: 16px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                min-height: 200px;
                transition: background-color .2s ease-in-out, color .2s ease-in-out;
                @media(min-width: 576px) {
                    min-height: 300px;
                }
                i {
                    transition: color .2s ease-in-out;
                }
                &:hover {
                    @media(min-width: 1025px) {
                        background-color: $color-green;
                        &, i {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
}
