#header {
    &:hover {
        .bottomnav {
            background-color: $color-black-0;
        }
    }
    .header-container .header-top {
        .wrapper {
            position: fixed;
            width: 100%;
            z-index: 99;
            transition:
                top .2s ease-in-out,
                background-color .2s ease-in-out;
            @media(min-width: 430px) {
                top: 40px;
            }
        }
    }
    &.menu-sticky {
        .header-container .header-top {
            .wrapper.brilliantMenu-top {
                @media(min-width: 1199px) {
                    top: 40px;
                }
            }
        }
    }
    .header-nav {
        max-height: none;
        margin: 0;
        background-color: transparent;
        display: none;
        @media(min-width: 430px) {
            display: block;
        }
    }
}

.bottomnav {
    background-color: rgba(0,0,0,0.75);
    transition: background-color .2s ease-in-out;
    backdrop-filter: blur(13px);
    position: relative;
    z-index: 3;
    &:hover {
        color: $color-white;
    }
    #contact-top {
        color: $color-white;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1200px;
        padding: 0 15px;
        margin: 0 auto;
        float: none;
        align-items: center;
        font-weight: 500;
        min-height: 40px;
        .block {
            ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;
                li {
                    font-weight: 700;
                    margin-right: 30px;
                    &:first-child {
                        order: 2;
                        .media {
                            .media-body {
                                a {
                                    &:before {
                                        content: "";
                                        display: inline-block;
                                        height: 18px;
                                        width: 20px;
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-image: url("../img/svg/mail.svg");
                                        @media(min-width: 768px) {
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        font-family: 'Arial', sans-serif;
                        order: 1;
                        @media(max-width: 767px) {
                            margin-right: 10px;
                        }
                        .media {
                            .media-body {
                                a {
                                    &:before {
                                        content: "";
                                        display: inline-block;
                                        height: 20px;
                                        width: 20px;
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-image: url("../img/svg/phone.svg");
                                        @media(min-width: 768px) {
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .media {
                        margin: 0;
                        padding: 0;
                        .media-body {
                            display: block;
                            width: auto;
                            overflow: visible;
                            a {
                                color: $color-white;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                align-content: center;
                                @media(max-width: 767px) {
                                    font-size: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .block-social {
            ul {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                li {
                    border: none;
                    width: auto;
                    height: auto;
                    border-radius: 0;
                    display: block;
                    line-height: normal;
                    margin: 0 10px 0 0;
                    &:before {
                        display: none;
                    }
                    a {
                        width: auto;
                        height: auto;
                        color: $color-white;
                        position: static;
                        font-size: 0;
                        &:before {
                            content: "";
                            display: inline-block;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }
                        &, * {
                            text-indent: 0;
                        }
                    }
                }
            }
            .facebook {
                a {
                    &:before {
                        width: 13px;
                        height: 20px;
                        background-image: url("../img/svg/fb.svg");
                    }
                }
            }
            .instagram {
                a {
                    &:before {
                        width: 20px;
                        height: 22px;
                        background-image: url("../img/svg/inst.svg");
                    }
                }
            }
        }
        .user-info-block {
            margin: 0 0 0 auto;
            justify-self: flex-end;
            align-self: center;
            .account_dropdown {
                display: none;
            }
            .dropdown-menu {
                position: static;
                background-color: transparent;
                transform: none;
                padding: 0;
                margin: 0;
                box-shadow: none;
                min-width: auto;
                font-size: 16px;
                font-weight: 700;
                a {
                    color: $color-white;
                }
            }
        }
        .block,
        .block-social {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            padding: 0;
            width: auto;
        }
    }
}
