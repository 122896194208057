#hp-category, #hp-category-left, #hp-category-right {
    .swiper-hp-category.swiper-container,
    .swiper-hp-category-left.swiper-container,
    .swiper-hp-category-right.swiper-container {
        margin-bottom: 0;
        .box-show-more {
            display: none;
        }
        .swiper-wrapper {
            margin-bottom: 0;
            padding: 50px 0;
            .swiper-slide {
                padding: 0 15px;
                @media(min-width: 1280px) {
                    padding: 0 30px;
                }
                .base-product {
                    display: block;
                    border-radius: 16px;
                    box-shadow: 0px 0px 25px rgba(0,0,0,0.2);
                    position: relative;
                    overflow: hidden;
                    padding-bottom: 60px;
                    &__image {
                        width: 100%;
                        background-position: center;
                        background-size: cover;
                        margin: 0;
                        background-repeat: no-repeat;
                        padding-bottom: 75%;
                        @media(min-width: 1366px) {
                            padding-bottom: 100%;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            top: 0;
                            background: $color-gray-light;
                            opacity: 0.2;
                        }
                        a {
                            position: relative;
                            z-index: 3;
                        }
                        img {
                            opacity: 0;
                            position: absolute;
                        }
                    }
                    &__text {
                        width: 100%;
                        height: 100%;
                        background-color: inherit;
                        padding: 0 15px;
                        position: static;
                    }
                    &__highlighted {
                        display: none;
                    }
                    &__title {
                        &, a {
                            font-size: 22px;
                            line-height: 28px;
                            margin: 0 0 3px;
                        }
                    }
                    &__category {
                        display: inline-flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        background-color: $color-green;
                        color: $color-white;
                        font-size: 11px;
                        line-height: 11px;
                        height: 24px;
                        font-weight: 700;
                        letter-spacing: 0.1em;
                        padding: 0 5px;
                        text-transform: uppercase;
                        border-radius: 3px;
                        margin-bottom: 15px;
                        padding-top: 1px;

                    }
                    &__price {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        margin: 0;
                        background-color: $color-green;
                        border-radius: 0;
                        border-top-left-radius: 16px;
                    }
                    &__actions {
                        display: none;
                    }
                }
            }
        }
    }
}
