#home-section {
    position: relative;
    max-height: 850px;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background-color: rgba(0,0,0,0.35);
    }
    .home {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        min-height: 550px;
        @media(min-width: 576px) {
            min-height: 700px;
        }
        @media(min-width: 1025px) {
            background-position: center;
        }
        &__description {
            position: absolute;
            width: 100%;
            max-width: 1200px;
            padding: 0 15px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            z-index: 6;
            top: 250px;
            left: 0;
            right: 0;
            pointer-events: none;
        }
        &__header {

            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            font-weight: 700;
            color: $color-white;
            pointer-events: none;
            text-align: center;
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 10px;
            @media(min-width: 400px) {
                font-size: 40px;
                line-height: 46px;
            }
            @media(min-width: 576px) {
                font-size: 54px;
                line-height: 62px;
                text-align: left;
            }
            @media(min-width: 768px) {
                font-size: 64px;
                line-height: 72px;
            }
        }
        &__text {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            color: $color-white;
            pointer-events: none;
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            @media(min-width: 400px) {
                font-size: 18px;
                line-height: 24px;
            }
            @media(min-width: 576px) {
                font-size: 24px;
                line-height: 30px;
                text-align: left;
            }
            @media(min-width: 768px) {
                font-size: 30px;
                line-height: 38px;
            }
        }
        &__image {
            img {
                opacity: 0;
            }
        }
    }

}
