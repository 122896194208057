#shop-open {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.4);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity .2s ease-in-out;
    z-index: 999999;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    &.open {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
    }
}
.shop-open {
    &__wrapper {
        width: 100%;
        max-width: 800px;
        padding: 60px;
        box-shadow: 3px 6px 60px rgba(0,0,0,0.2);
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0 15px;
        background-image: url("../img/popup.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0,0,0,0.35);
        }
    }
    &__logo {
        width: 180px;
        position: relative;
        z-index: 6;
        &:after {
            content: "";
            display: block;
            width: 150px;
            height: 150px;
            background-image: url("../img/logo-white.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    &__text {
        max-width: calc(100% - 180px);
        width: 100%;
        background-color: rgba(255,255,255,0.85);
        text-align: center;
        padding: 30px 15px;
        position: relative;
        z-index: 6;
        P {
            font-size: 18px;
            font-weight: 600;
            color: $color-font-main;
            margin-bottom: 30px;
            &:last-child {
                text-align: right;
            }
        }
    }
    &__close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 3;
        cursor: pointer;
        span {
            height: 5px;
            width: 100%;
            display: block;
            background-color: $color-white;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            transform-origin: 50% 50%;
            transform: rotate(45deg);
            &:first-child {
                transform: rotate(-45deg);
            }
        }
    }
}
