#hp-articles-list {
    margin-bottom: 90px;
    .hp-articles-list {
        max-width: 1200px;
        padding: 0 15px;
        margin: 0 auto;
        &__list {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            border-radius: 16px;
            overflow: hidden;
            background-color: $color-white;
            box-shadow: 0 0 99px rgba(0,0,0,0.16);
        }
        &__item {
            position: relative;
            width: 100%;
            @media(min-width: 576px) {
                width: 50%;
            }
            @media(min-width: 768px) {
                width: calc(100% / 3);
            }
            &-image {
                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                    max-height: none;
                }
                &-wrap {
                    display: block;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    @media(max-width: 575px) {
                        min-height: 300px;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-color: rgba(0,0,0,0.4);
                    }
                    img {
                        @media(max-width: 575px) {
                            position: absolute;
                            opacity: 0;
                        }
                    }
                }
            }
            &-blogicons {
                display: none;
            }
            &-category {
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                background-color: $color-green;
                color: $color-white;
                font-size: 11px;
                line-height: 11px;
                height: 24px;
                font-weight: 700;
                letter-spacing: 0.1em;
                padding: 0 5px;
                text-transform: uppercase;
                border-radius: 3px;
                margin-bottom: 5px;
                padding-top: 1px;
            }
            &-title {
                margin: 0;
                padding: 0;
                color: $color-white;
            }
            &-date {
                text-align: right;
                width: 100%;
                color: $color-white;
                font-weight: 500;
                font-size: 12px;
            }
            &-link {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-end;
                align-content: flex-end;
                width: 100%;
                height: 100%;
            }
            &-text {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 15px;
                right: 0;
                color: $color-white;
                padding: 0 15px;
                @media(min-width: 576px) {
                    padding: 0 30px;
                }
            }
            &-title {
                display: block;
                width: 100%;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 5px;
            }
            &:nth-child(2) {
                @media(max-width: 767px) {
                    width: 100%;
                }
                .hp-articles-list__item-image-wrap {
                    @media(max-width: 767px) {
                        min-height: 300px;
                    }
                    img {
                        @media(max-width: 767px) {
                            position: absolute;
                            opacity: 0;

                        }
                    }
                }
            }
            &:first-child {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                .hp-articles-list__item-category {
                    margin-bottom: 15px;
                }
                .hp-articles-list__item-image-wrap {
                    width: 100%;
                    min-height: 260px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    @media(min-width: 768px) {
                        max-width: 40%;
                    }
                    &:after {
                        display: none;
                    }
                    img {
                        opacity: 0;
                    }
                }
                .hp-articles-list__item-title {
                    color: $color-font-main;
                    font-size: 24px;
                    line-height: 30px;
                    @media(min-width: 1200px) {
                        font-size: 30px;
                        line-height: 38px;
                    }
                }
                .hp-articles-list__item-description {
                    color: $color-font-main;
                    margin: 0 0 5px;
                    font-size: 14px;
                    line-height: 22px;
                    @media(min-width: 1025px) {
                        font-size: 16px;
                        line-height: 26px;
                    }
                    @media(min-width: 1200px) {
                        font-size: 17px;
                        line-height: 28px;
                    }
                }
                .hp-articles-list__item-date {
                    color: $color-font-main;
                }
                .hp-articles-list__item-text {
                    position: relative;
                    width: 100%;
                    @media(max-width: 767px) {
                        padding: 0 15px;
                        margin-top: 10px;
                    }
                    @media(min-width: 768px) {
                        max-width: 60%;
                    }
                    .hp-articles-list__item-link {
                        display: block;
                    }
                }
            }
        }
    }
}
