* {
    box-sizing: border-box;
}
.disable-column {
    display: block;
    float: none !important;
    width: 100% !important;
    max-width: 100% !important;
    clear: both !important;
    padding: 0 !important;
}
#wrapper {
    & > .row {
        margin: 0 !important;
    }
    & > .container {
        margin-top: 45px !important;
        @media(min-width: 1025px) {
            margin-top: 100px !important;
        }
    }
}
// hard reset container class
.container {
    max-width: 1200px !important;
    padding: 0 15px !important;
    width: 100% !important;
    margin: 0 auto !important;
}
