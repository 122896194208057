html body {
    .pagination {
        margin-bottom: 45px;
        .row {
            .page-list {
                margin: 0 auto;
                float: none;
                .page-item {
                    @media(max-width: 575px) {
                        &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
