#main-top {
    .swiper-products.swiper-container {
        margin-bottom: 60px;
        .swiper-products__title {
            text-align: center;
            color: $color-green;
            padding: 0;
            margin: 0 0 15px;
            font-size: 24px;
            @media(min-width: 576px) {
                font-size: 28px;
            }
            @media(min-width: 768px) {
                font-size: 36px;
            }
        }
        .swiper-wrapper {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .swiper-slide {
            box-shadow: 0px 0px 55px rgba(0,0,0,0.2);
            background-color: $color-gray-light;
            border-radius: 16px;
            overflow: hidden;
            .base-product {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                &__image {
                    width: 100%;
                    background-position: center;
                    background-size: cover;
                    margin: 0;
                    padding-bottom: 0;
                    @media(min-width: 576px){
                        max-width: 50%;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        background: $color-gray-light;
                        opacity: 0.2;
                    }
                    a {
                        position: relative;
                        z-index: 3;
                    }
                    img {
                        opacity: 0;
                        position: relative;
                    }
                }
                &__text {
                    width: 100%;
                    height: 100%;
                    background-color: inherit;
                    position: static;
                    padding: 10px 15px 45px;
                    @media(min-width: 576px){
                        max-width: 50%;
                        padding: 0 30px 0 15px;
                    }
                }
                &__description {
                    @media(max-width: 767px) {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
                &__highlighted {
                    display: none;
                }
                &__title {
                    &, a {
                        margin: 0 0 3px;
                        font-size: 20px;
                        line-height: 26px;
                        @media(min-width: 758px) {
                            font-size: 24px;
                            line-height: 30px;
                        }
                    }
                }
                &__category {
                    display: inline-flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    background-color: $color-green;
                    color: $color-white;
                    font-size: 11px;
                    line-height: 11px;
                    height: 24px;
                    font-weight: 700;
                    letter-spacing: 0.1em;
                    padding: 0 5px;
                    text-transform: uppercase;
                    border-radius: 3px;
                    margin-bottom: 15px;
                    padding-top: 1px;
                }
                &__price {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    margin: 0;
                    background-color: $color-green;
                    border-radius: 0;
                    border-top-left-radius: 16px;
                }

                &__actions {
                    display: none;
                }
            }
        }
    }
}
