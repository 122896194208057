#module-smartblog-category {
    #wrapper {
        & > .container {
            & > .row {
                display: flex;
                flex-wrap: wrap;
                #left-column {
                    order: 2;
                    padding: 0 15px;
                    float: none;
                    width: 100%;
                    max-width: 25%;
                    margin: 0;
                    display: none;
                    @media(min-width: 1025px) {
                        display: block;
                    }
                }
                #content-wrapper {
                    order: 1;
                    padding: 0 15px !important;
                    float: none;
                    display: block;
                    width: 100%;
                    margin: 0;
                    @media(min-width: 1025px) {
                        max-width: 75%;
                    }
                }
            }
        }
    }
    #smartblogcat {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .sdsarticleCat {
            width: 100%;
            display: block;
            margin: 0 0 30px;
            border-radius: 16px;
            overflow: hidden;
            box-shadow: 0 0 30px rgba(0,0,0,0.16);
            background-color: $color-white;
            .articleContent {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                .articleContentImg {
                    border-radius: 0;
                    overflow: hidden;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                    @media(min-width: 576px) {
                        max-width: 35%;
                    }
                    .sdsarticleHeader {
                        display: none;
                    }
                    &:after {
                        content: "";
                        display: block;
                        padding-bottom: 56.25%;
                        @media(min-width: 576px) {
                            padding-bottom: 100%;
                        }
                        @media(min-width: 768px) {
                            padding-bottom: 75%;
                        }
                    }
                    img {
                        border-radius: 0;
                        position: absolute;
                        opacity: 0;
                    }
                }
                .sdsarticle-des {
                    width: 100%;
                    padding: 15px;
                    @media(min-width: 576px) {
                        max-width: 65%;
                    }
                    @media(min-width: 768px) {
                        padding: 30px;
                    }
                    .title_block {
                        padding: 0;
                        margin-bottom: 15px;
                        font-size: 24px;
                        line-height: 30px;
                        @media(min-width: 576px) {
                            font-size: 20px;
                            line-height: 24px;
                        }
                        @media(min-width: 768px) {
                            font-size: 24px;
                            line-height: 30px;
                        }
                    }
                    .sdsreadMore {
                        margin-top: 15px;
                        text-align: right;
                        a {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
    #left-column {
        .block-categories {
            margin-top: 0;
            margin-bottom: 30px;
            display: none;
        }
    }
}
